import { Form, Input, } from 'antd'
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';

function ColorsForm({ onFinish, editingColors, setEditingColors, form }) {
    const { t } = useTranslation();


    useEffect(() => {
        if (editingColors) {
            form.setFieldsValue(editingColors);
        } else {
            form.resetFields();
        }
    }, [editingColors]);

    return (
        <div>
            <Form
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                form={form}
                onFinish={onFinish}
                requiredMark="optional"  // Prevents default left-side asterisk
            >
                <Form.Item
                    rules={[{ required: true, message: "Color name must only contain alphabet letters with a maximum of 15 characters", pattern: /^(?=.{1,15}$)[a-zA-Z ,.'-]+$/i, }]}
                    label={
                        <span className="flex items-center gap-1">
                            {t("vd_pc_Color_n")}
                            <span className="text-red-500">*</span>
                        </span>
                    }
                    name="color_name"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    rules={[{ required: true, message: "Color name must only contain arabic letters", pattern: /^[\u0600-\u06FF ,.'-]+$/i, }]}
                    label={
                        <span className="flex items-center gap-1">
                            {t("color_arabic")}
                            <span className="text-red-500">*</span>
                        </span>
                    }
                    name="color_name_ar"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    rules={[{ required: true, message: "Color code is required",}]}
                    label={
                        <span className="flex items-center gap-1">
                            {t("vd_pc_Color_c")}
                            <span className="text-red-500">*</span>
                        </span>
                    }
                    name="color_code"
                >
                    <Input type='color' />
                </Form.Item>

                <button className=" btn btn-primary w-full rounded-md text-white" type="submit">{editingColors ? t("Update") : t("vd_pc_btn")}</button>
            </Form>
        </div>
    )
}

export default ColorsForm;
