import { Space, Table } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next';
import DeleteColorsIcon from './DeleteColorsIcon';
import EditColorsIcon from './EditColorsIcon';

function ColorsTable({ colorsData, loading, onEdit }) {

    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';



    const columns = [

        {
            title: t("vd_pc_C_ID"),
            key: 'S no',
            dataIndex: "color_id",
            align: isArabic ? 'right' : 'left',
            ellipsis: true, // ✅ Adds "..." if text overflows
        },
        {
            title: `Sample`,
            key: 'color_code',
            dataIndex: "color_code",
            align: isArabic ? 'right' : 'left',
            ellipsis: true, // ✅ Adds "..." if text overflows
            render: (color_code) => (
                <div
                    style={{ backgroundColor: color_code }}
                    className={`w-8 h-4 border border-primary`}
                ></div>
            )
        },

        {
            title: t("vd_pc_Color_n"),
            key: 'name',
            dataIndex: isArabic ? 'color_name_ar' : 'color_name',
            align: isArabic ? 'right' : 'left',
            ellipsis: true, // ✅ Adds "..." if text overflows
        },

        {
            title: t("vd_pc_C_Options"),
            key: 'actions',
            width: 120,
            align: 'center',
            fixed: 'right', // ✅ Keeps actions visible on scroll
            render: (colors) => (
                <Space>
                    <DeleteColorsIcon colors={colors} />
                    <EditColorsIcon colors={colors} onEdit={() => onEdit(colors)} />
                </Space>
            ),
        }
    ]
    return (
        <div>

            <Table
                aria-label={t('Colors')}
                rowKey="colors_id"
                loading={loading}
                dataSource={colorsData}
                columns={columns}
                pagination={{ pageSize: 10 }}
                bordered
                scroll={{ x: "max-content" }}
                style={{ boxShadow: '0 4px 10px rgba(0,0,0,0.1)', borderRadius: '8px', overflow: 'hidden' }}

            />
        </div>
    )
}

export default ColorsTable
