import Search from "antd/es/input/Search";
import React, { useCallback } from "react";

export const ProductSearch = React.memo(({ onSearch }) => {
    const handleSearch = useCallback((value) => {
        onSearch(value.trim()); // Trim whitespace to avoid unnecessary searches
    }, [onSearch]);

    return (
        <Search
            placeholder="Input search text..."
            allowClear
            enterButton="Search"
            size="large"
            onSearch={handleSearch}
            aria-label="Search"
            className="custom-search"
        />
    );
});
