import { useMutation} from '@tanstack/react-query';
import { createOrEditSize } from "../../sizes";

export const useCreateOrEditSize = ({ onSuccess, onError }) => {
    return useMutation({
        mutationKey: [ 'createOrEditSize' ],
        mutationFn: createOrEditSize,
        onSuccess,
        onError
   })
}
