import {useMutation} from '@tanstack/react-query';
import {deleteTag } from "../../tags";

export const useDeleteTag = ({ tagId, onSuccess, onError }) => {
    return useMutation({
        mutationKey: [ 'deleteTag', tagId ],
        mutationFn: (tagId) => deleteTag(tagId),
        onSuccess,
        onError
   })
}
