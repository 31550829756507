import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function PopupAlert({ tags, colors, sizes, categories }) {
  const { i18n, t } = useTranslation();
  const [open, setOpen] = useState(false);

  // Check if modal should be open (Only set state when necessary)
  useEffect(() => {
    const hasMissingData = [sizes, tags, categories, colors].some(
      (query) => query?.data?.length === 0
    );
    if (hasMissingData !== open) {
      setOpen(hasMissingData);
    }
  }, [sizes?.data, tags?.data, categories?.data, colors?.data, open]);

  return (
    <Modal open={open} footer={false} closable={false} centered>
      <div>
        <h4 className="mb-4 text-[#28716c]">
          {i18n.language === "ar"
            ? "يرجى التأكد من إضافة العلامات والألوان والأحجام والفئات قبل إضافة المنتج"
            : "Please make sure that tags, colors, sizes and categories are added before adding a product."}
        </h4>

        <pre>
          {categories?.data?.length === 0 && (
            <>
              {t("Navigate here to add new category")}:{" "}
              <Link to="/vendor/categories" className="text-primary">
                {t("Add new category")}
              </Link>
              <br />
            </>
          )}

          {sizes?.data?.length === 0 && (
            <>
              {t("Navigate here to add new size")}:{" "}
              <Link to="/vendor/size" className="text-primary">
                {t("Add new size")}
              </Link>
              <br />
            </>
          )}

          {tags?.data?.length === 0 && (
            <>
              {t("Navigate here to add new tags")}:{" "}
              <Link to="/vendor/tags" className="text-primary">
                {t("Add new tag")}
              </Link>
              <br />
            </>
          )}

          {colors?.data?.length === 0 && (
            <>
              {t("Navigate here to add new colors")}:{" "}
              <Link to="/vendor/color" className="text-primary">
                {t("Add new color")}
              </Link>
              <br />
            </>
          )}
        </pre>
      </div>
    </Modal>
  );
}

export default PopupAlert;
