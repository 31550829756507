import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import i18next from "i18next";
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import Button from "../utils/Button";
import { useTranslation } from "react-i18next";
import ChangeLanguage from "../../locales/ChangeLanguage";
import logo from "../../assets/logo.png";
import { ArrowDropDown } from "@mui/icons-material";

const Header = ({ navigation }) => {
  const { t } = useTranslation();
  const [navOpen, setNavOpen] = useState(false);
  let selectLanguage = i18next.language;
  const [helpshow, setHelpshow] = useState(false);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  function closeNav() {
    setNavOpen(false);
  }

  function handleHelp() {
    setNavOpen(false);
    localStorage.setItem("guideHeader", "Create your store");
  }

  return (
    <div className="w-full border-b shadow border-gray-200 bg-[#fff] sticky z-50">
      <header className="h-[16vh] container mx-auto max-w-[1280px] px-5 medium:px-4 lg:px-10 drop-shadow-md-top py-1 md:py-2 flex justify-between items-center">
        <div className="brand-logo w-24 md:w-32 p-1 md:p-2 ">
          <Link to="/">
            <img src={logo} className="cursor-pointer" alt="logo" />
          </Link>
        </div>

        <div className="navbar-center hidden lg:flex text-black font-semibold mr-4 medium:mr-10 md:mr-5">
          <ul className="menu menu-horizontal px-1 home-link mb-0">
            <li>
              <Link
                to="/"
                className="text-[#236260] hover:text-primary duration-100 text-[18px] font-Roboto bg-transparent active:text-primary"
              >
                {t("HNav_home")}
              </Link>
            </li>
            <li>
              <Link
                to="/about-us"
                className="text-[#236260] hover:text-primary duration-100 text-[18px] font-Roboto bg-transparent"
              >
                {selectLanguage === "ar" ? "من نحن" : "About Us"}
              </Link>
            </li>
            <li>
              <Link
                to="/plans-pricing"
                className="text-[#236260] hover:text-primary duration-100 text-[18px] font-Roboto bg-transparent"
              >
                {t("plansPrice")}
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  localStorage.setItem("guideHeader", "Create your store");
                }}
                to="/how-to-create-new-online-store-with-AiTech/create-store"
                className="text-[#236260] hover:text-primary duration-100 text-[18px] font-Roboto bg-transparent"
              >
                {selectLanguage === "ar"
                  ? "كيفية إعداد متجر"
                  : "How to setup A Store"}
              </Link>
            </li>
            {/* <li>
              <Link
                to="/help"
                className="text-[#236260] hover:text-primary duration-100 text-[18px] font-Roboto bg-transparent"
              >
                {selectLanguage === "ar" ? "مساعدة" : "Help"}
              </Link>
              <ul className="bg-white border-2 mt-[-8px] h-14 px-2 text-center">
                <li>
                  <Link
                    onClick={() => {
                      localStorage.setItem("guideHeader", "Create your store");
                    }}
                    to="/how-to-create-new-online-store-with-AiTech/create-store"
                    className="text-[#236260] hover:text-primary duration-100 text-[18px] font-Roboto bg-transparent"
                  >
                    {selectLanguage === "ar"
                      ? "كيفية إعداد متجر"
                      : "How to setup A Store"}
                  </Link>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>

        <div className="navigation hidden lg:block">
          <ul className="flex items-center gap-x-4 medium:gap-x-8 md:text-md lg:text-lg font-Roboto font-[500] mb-0">
            {navigation && (
              <>
                <li>
                  <Button link={"/vendor/register"} bgColor="bg-primary">
                    {t("Create_free_Store")}
                  </Button>
                </li>
                <li>
                  <NavLink
                    className="in-pri duration-100 font-Roboto"
                    to="/vendor/login"
                  >
                    <span className="flex text-[#236260] hover:text-primary text-[18px] items-center">
                      {t("HNav_login")}
                    </span>
                  </NavLink>
                </li>{" "}
              </>
            )}
            <li>
              <ChangeLanguage />
            </li>
          </ul>
        </div>

        {/* mobile navigation */}
        <div className="mobile-navigation pr-8 lg:hidden flex items-center">
          <div className="mr-3">
            <ChangeLanguage />
          </div>
          {navigation && navOpen ? (
            <AiOutlineClose onClick={toggleNav} size="25" cursor="pointer" />
          ) : (
            <FaBars onClick={toggleNav} size="25" cursor="pointer" />
          )}
        </div>
      </header>
      {navOpen && (
        <div className="w-full bg-[#f9f9f9] lg:hidden">
          <ul className="flex flex-col text-lg font-Roboto !text-primary px-5 py-5 ">
            <li className="border-b border-[#236260] w-full">
              <Link
                to="/"
                className="text-[#236260] text-[18px] font-Roboto bg-transparent hover:bg-primary hover:text-white w-full block px-2 py-2 duration-200 ease-linear"
                onClick={closeNav}
              >
                {t("HNav_home")}
              </Link>
            </li>
            <li className="border-b border-[#236260] w-full">
              <Link
                to="/about-us"
                className="text-[#236260] text-[18px] font-Roboto bg-transparent hover:bg-primary hover:text-white w-full block px-2 py-2 duration-200 ease-linear"
                onClick={closeNav}
              >
                {selectLanguage === "ar" ? "عنا نحن" : "About Us"}
              </Link>
            </li>
            <li className="border-b border-[#236260] w-full">
              <Link
                to="/plans-pricing"
                className="text-[#236260] text-[18px] font-Roboto bg-transparent hover:bg-primary hover:text-white w-full block px-2 py-2 duration-200 ease-linear"
                onClick={closeNav}
              >
                {t("plansPrice")}
              </Link>
            </li>
            <li className="border-b border-[#236260] w-full">
              <Link
                onClick={() => {
                  handleHelp();
                }}
                to="/how-to-create-new-online-store-with-AiTech/create-store"
                className="text-[#236260] text-[18px] font-Roboto bg-transparent hover:bg-primary hover:text-white w-full block px-2 py-2 duration-200 ease-linear"
              >
                {selectLanguage === "ar"
                  ? "كيفية إعداد متجر"
                  : "How to setup a store"}
              </Link>
            </li>
            {/* <li className="border-b border-[#236260] w-full">
              <div className="flex flex-row items-center">
                <Link
                  to="/help"
                  className="text-[#236260] text-[18px] font-Roboto bg-transparent hover:bg-primary hover:text-white block w-full px-2 py-2 duration-200 ease-linear"
                  onClick={closeNav}
                >
                  {selectLanguage === "ar" ? "مساعدة" : "Help"}
                </Link>
                <ArrowDropDown
                  className="text-2xl cursor-pointer text-[#236260]"
                  onClick={(e) => {
                    setHelpshow((prev) => !prev);
                    e.stopPropagation();
                  }}
                />
              </div>
              {helpshow && (
                <ul>
                  <li className="border-t border-[#236260] w-full">
                    <Link
                      onClick={() => {
                        handleHelp();
                      }}
                      to="/how-to-create-new-online-store-with-AiTech/create-store"
                      className="text-[#236260] text-base font-Roboto bg-transparent hover:bg-primary hover:text-white w-full block px-2 py-1 duration-200 ease-linear"
                    >
                      {selectLanguage === "ar"
                        ? "كيفية إعداد متجر"
                        : "How to setup a store"}
                    </Link>
                  </li>
                </ul>
              )}
            </li> */}
            <li className="border-b border-[#236260] w-full mb-3">
              <NavLink
                className="text-[#236260] text-[18px] font-Roboto bg-transparent hover:bg-primary hover:text-white w-full block px-2 py-2 duration-200 ease-linear"
                onClick={closeNav}
                to="/vendor/login"
              >
                {t("HNav_login")}
              </NavLink>
            </li>
            <li>
              <Button link={"/vendor/register"} bgColor="bg-primary">
                {t("Create_free_Store")}
              </Button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Header;
