import { DeleteTwoTone } from "@ant-design/icons";
import { Alert, notification, Popconfirm } from 'antd';
import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query';
import { useDeleteSize } from '../../../api/hooks/sizes';

function DeleteSizesIcon({ sizes }) {

    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const queryClient = useQueryClient();

    const deleteMutation = useDeleteSize({
        onSuccess: () => {
            closeConfirmation()
            notification.success({ message: `Size successfully deleted` })
            queryClient
                .invalidateQueries({ queryKey: ["listSizes"] })
                .catch(() => notification.error({ message: "Failed to reload product list" }))
        },
        onError: (e) => {
            console.error(e)
            setError(true)
        }
    })

    const onOk = () => deleteMutation.mutate(sizes.size_id)
    const openConfirmation = () => setOpen(true)
    const closeConfirmation = () => {
        setError(false)
        setOpen(false)
    }
    const confirmationContents = React.useMemo(() => {
        return <>
            {`Permanently delete size ${sizes.size_name}?`}
            {error && <Alert
                type={'error'}
                message='Failed to delete product'
                description='Please try again or contact switch support if the problem persists'
            />}
        </>
    }, [sizes, error])
    return (
        <Popconfirm
            title={confirmationContents}
            open={open}
            onCancel={closeConfirmation}
            okText="Confirm"
            onConfirm={onOk}
            okButtonProps={{
                loading: deleteMutation.isLoading,
                style: { backgroundColor: "#f4900c", borderColor: "#f4900c", color: "white" }
            }}
            placement='left'
        >
            <DeleteTwoTone twoToneColor='#ff4d4f' onClick={openConfirmation} />
        </Popconfirm>
    )
}

export default DeleteSizesIcon;
