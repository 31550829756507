import React, { useEffect, useState } from 'react'
import { Alert, Form, notification } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useListTags } from '../../../api/hooks/tags/useListTags';
import { useCreateOrEditTag } from '../../../api/hooks/tags/useCreateOrEditTag';
import TagsForm from './TagsForm';
import TagsTable from './TagsTable';

function TagsManagement() {
    const { t } = useTranslation();
    const [getTags, setTags] = useState([]);
    const [editingTags, setEditingTags] = useState(null);
    const tagsQuery = useListTags({});
    const queryClient = useQueryClient();
    const [form] = Form.useForm();

    useEffect(() => {
        if (tagsQuery.data) {
            setTags(tagsQuery.data);
        }
    }, [tagsQuery.data]);

    const onErrorWrapper = (error) => {
        console.log(error);

    };

    const onSuccessWrapper = (response) => {
        if (response) {
            notification.success({
                message: response?.message, // Translated text
            });
        } else {
            notification.error({
                message: t("something_went_wrong"),
            });
        }
        queryClient
            .invalidateQueries({ queryKey: ["listTags"] })
    };

    // listCategories
    const mutation = useCreateOrEditTag({
        onSuccess: onSuccessWrapper,
        onError: onErrorWrapper,
    });

    const onFinish = (values) => {
        if (editingTags) {

            mutation.mutate({ payload: { ...values, id: editingTags.tag_id }, mode: "edit" });
        } else {
            mutation.mutate({ payload: values, mode: "create" });
        }
        setEditingTags(null); // Reset after save
        form.resetFields();
    };

    const onEdit = (size) => {
        setEditingTags(size);
    };
    return (
        <div>
            <h2 className="text-title text-lg md:text-2xl uppercase">
                {t("vd_pt_Tags")}
            </h2>
            <div className="p-4 border border-gray-200 bg-white shadow-sm col-span-2 mt-5">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {/* Form takes 1/3 width */}
                    <div className="md:col-span-1">
                        <TagsForm form={form} onFinish={onFinish} editingTags={editingTags} setEditingTags={setEditingTags} />
                    </div>

                    {/* Table takes 2/3 width */}
                    <div className="md:col-span-2">
                        <TagsTable tagsData={getTags} loading={tagsQuery.isLoading} onEdit={onEdit} />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TagsManagement
