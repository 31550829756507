import { Col, Form, Input, Row, Select } from "antd";
import React, { useState } from "react";
import { Alert, Spin } from "antd";
import { useCreateOrEditProduct } from "../../../api/hooks/products";
import { useListTags } from "../../../api/hooks/tags";
import { useListColors } from "../../../api/hooks/colors";
import { useListCategories } from "../../../api/hooks/categories";
import { useListSizes } from "../../../api/hooks/sizes";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import { ImageUpload } from "../../../components/ImageUpload";
const { Option } = Select;

const DefaultErrorAlert = () => {
  return (
    <Alert
      type="error"
      message={
        <>
          An error has occurred. <br />
          Please try again or contact support if the problem persists.
        </>
      }
      closable
      style={{ marginBottom: 25 }}
    />
  );
};

export const ProductForm = ({ mode, form, product, onSuccess ,tags, colors, sizes, categories }) => {
  const [alert, setAlert] = useState(null);
  const { t } = useTranslation();

  const onErrorWrapper = (error) => {
    if (error.response.status === 400) {
      const errorMessage = error.response.data.errors[0].message;
      setAlert(
        <Alert
          message={errorMessage}
          type="error"
          style={{ marginBottom: 25 }}
          closable
        />
      );
    } else {
      setAlert(<DefaultErrorAlert />);
    }
  };

  const onSuccessWrapper = (response) => {
    if (typeof onSuccess === "function") {
      onSuccess(response);
    }
    setAlert(null);
  };

  const mutation = useCreateOrEditProduct({
    onSuccess: onSuccessWrapper,
    onError: onErrorWrapper,
  });

  const onFinish = (values) => {
    const formData = new FormData();
    const appendArrayField = (field) => {
      if (Array.isArray(values[field])) {
        formData.append(field, JSON.stringify(values[field]));
      }
    };

    Object.keys(values).forEach((key) => {
      if (
        ![
          "thumbnail_image",
          "gallery_images",
          "colors",
          "sizes",
          "tags",
        ].includes(key)
      ) {
        formData.append(key, values[key]);
      }
    });
    if (mode === "edit") {
      formData.append("product_id", product.product_id);
    }
    appendArrayField("colors");
    appendArrayField("sizes");
    appendArrayField("tags");

    if (values.thumbnail_image?.[0]?.response?.files?.[0]?.location) {
      formData.append(
        "thumbnail_image",
        values.thumbnail_image?.[0]?.response?.files?.[0]?.location
      );
    }

    if (values.gallery_images) {
      values.gallery_images?.forEach((el) => {
        if (el?.url) {
          formData.append("gallery_images", el?.url);
        }
        else if (el?.response?.files?.[0]?.location) {
          formData.append("gallery_images", el?.response?.files?.[0]?.location);
        } 
      });
    }

    mutation.mutate({ mode, productId: product?.productId, payload: formData });
  };

  return (
    <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      form={form}
      onFinish={onFinish}
    >
      <Spin spinning={mutation.isLoading}>
        {alert}
        <div className="card-title mb-2">
          <h3 className="text-base font-medium">{t("add_new_product_info")}</h3>
        </div>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              rules={[
                { required: true, message: t("add_new_product_name_req") },
              ]}
              label={
                <span className="flex items-center gap-1">
                  {t("add_new_product_name")}
                  <span className="text-red-500">*</span>
                </span>
              }
              name="product_name"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message:
                    t("add_new_product_name") +
                    " " +
                    t("in Arabic") +
                    " is required",
                },
                {
                  pattern: /^[\u0600-\u06FF ,.'-]+$/i,
                  message: "Enter only Arabic characters",
                },
              ]}
              label={
                <span className="flex items-center gap-1">
                  {t("add_new_product_name")} {t("in Arabic")}
                  <span className="text-red-500">*</span>
                </span>
              }
              name="product_name_ar"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <span className="flex items-center gap-1">
                  {t("add_new_product_price")}
                  <span className="text-red-500">*</span>
                </span>
              }
              name="product_price"
              type="number"
              rules={[
                { required: true, message: t("add_new_product_price_req") },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={
                <span className="flex items-center gap-1">
                  {t("add_new_product_SKU")}
                </span>
              }
              name="product_sku"
              type="text"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <span className="flex items-center gap-1">
                  {t("add_new_product_stock")}
                  <span className="text-red-500">*</span>
                </span>
              }
              name="product_stock"
              type="number"
              rules={[
                { required: true, message: t("add_new_product_stock_req") },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <div className="card-title mb-2">
          <h3 className="text-base font-medium">{t("Description")}</h3>
        </div>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={
                <span className="flex items-center gap-1">
                  {t("Description")}
                </span>
              }
              name="product_description"
            >
              <Form.Item name="product_description" noStyle>
                <ReactQuill
                  theme="snow"
                  onChange={(value) =>
                    form.setFieldsValue({ description: value })
                  }
                />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <span className="flex items-center gap-1">
                  {t("Description")} {t("in Arabic")}
                </span>
              }
              name="product_description_ar"
            >
              <Form.Item name="product_description_ar" noStyle>
                <ReactQuill
                  theme="snow"
                  onChange={(value) =>
                    form.setFieldsValue({ description: value })
                  }
                />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        <div className="card-title mb-2">
          <h3 className="text-base font-medium">
            {t("add_new_product_atrtibutes")}
          </h3>
        </div>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="colors"
              label={
                <span className="flex items-center gap-1">
                  {t("add_new_product_Colors")}
                  <span className="text-red-500">*</span>
                </span>
              }
              rules={[{ required: true, message: "Please select Color!" }]}
            >
              <Select mode="multiple" allowClear>
                {colors?.data?.map((color) => (
                  <Option key={color.color_id} value={color.color_id}>
                    {color.color_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <p className="text-primary text-[12px] my-1 opacity-90">
              +{" "}
              <Link to="/vendor/color" className="text-primary">
                {t("Add new color")}
              </Link>{" "}
            </p>
          </Col>
          <Col span={12}>
            <Form.Item
              name="sizes"
              label={
                <span className="flex items-center gap-1">
                  Size
                  <span className="text-red-500">*</span>
                </span>
              }
              rules={[{ required: true, message: "Please select Size!" }]}
            >
              <Select mode="multiple" allowClear>
                {sizes?.data?.map((size) => (
                  <Option key={size.size_id} value={size.size_id}>
                    {size.size_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <p className="text-primary text-[12px] my-1 opacity-90">
              +{" "}
              <Link to="/vendor/size" className="text-primary">
                {t("Add new size")}
              </Link>{" "}
            </p>
          </Col>
        </Row>
        <div className="card-title mb-2">
          <h3 className="text-base font-medium">
            {t("add_new_product_Information")}
          </h3>
        </div>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={
                <span className="flex items-center gap-1">
                  {t("add_new_product_Weight")}
                  <span className="text-red-500">*</span>
                </span>
              }
              name="product_weight"
              type="number"
              rules={[{ required: true }]}
            >
              <Input addonAfter={<span>gm</span>} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <span className="flex items-center gap-1">
                  {t("add_new_product_Height")}
                  <span className="text-red-500">*</span>
                </span>
              }
              name="product_height"
              type="number"
              rules={[{ required: true }]}
            >
              <Input addonAfter={<span>cm</span>} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <span className="flex items-center gap-1">
                  {t("add_new_product_length")}
                  <span className="text-red-500">*</span>
                </span>
              }
              name="product_length"
              type="number"
              rules={[{ required: true }]}
            >
              <Input addonAfter={<span>cm</span>} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <span className="flex items-center gap-1">
                  {t("add_new_product_width")}
                  <span className="text-red-500">*</span>
                </span>
              }
              name="product_width"
              type="number"
              rules={[{ required: true }]}
            >
              <Input addonAfter={<span>cm</span>} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="status"
              initialValue={1}
              label={
                <h3 className="text-base font-medium capitalize gap-1">
                  {t("add_new_product_Status")}
                  <span className="text-red-500">*</span>
                </h3>
              }
              rules={[{ required: true }]}
            >
              <Select
                options={[
                  { value: 1, label: t("add_new_product_Status_2") },
                  { value: 0, label: t("add_new_product_Status_3") },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="product_category"
              label={
                <h3 className="text-base font-medium capitalize gap-1">
                  {t("add_new_product_Category")}
                  <span className="text-red-500">*</span>
                </h3>
              }
              rules={[{ required: true }]}
            >
              <Select>
                {categories?.data?.map((cat) => (
                  <Option key={cat.category_id} value={cat.category_id}>
                    {cat.category_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <p className="text-primary text-[12px] my-1 opacity-90">
              +{" "}
              <Link to="/vendor/categories" className="text-primary">
                {t("Add new category")}
              </Link>{" "}
            </p>
          </Col>
          <Col span={8}>
            <Form.Item
              name="tags"
              label={
                <span className="flex items-center gap-1">
                  {t("add_new_product_Tags")}
                  <span className="text-red-500">*</span>
                </span>
              }
              rules={[{ required: true, message: "Please select Size!" }]}
            >
              <Select mode="multiple" allowClear>
                {tags?.data?.map((tag) => (
                  <Option key={tag.tag_id} value={tag.tag_id}>
                    {tag.tag_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <em>{t("add_new_product_Tags_info")}</em>
            <p className="text-primary text-[12px] mt-2 opacity-90">
              +{" "}
              <Link to="/vendor/tags" className="text-primary">
                {t("Add new tag")}
              </Link>{" "}
            </p>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <h3 className="text-base font-medium mt-4">Thumbnail Image</h3>
            <div className="pb-2">
              <Form.Item
                name="thumbnail_image"
              >
                <ImageUpload fieldName="thumbnail_image" />
              </Form.Item>
            </div>
          </Col>

          <Col span={12}>
            <h3 className="text-base font-medium mt-4">Gallery Images</h3>
            <div className="pb-2">
              <Form.Item
                name="gallery_images"
              >
                <ImageUpload
                  fieldName="gallery_images"
                  multiple={true}
                  maxCount={10}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <button
          className=" btn btn-primary rounded-md text-white"
          type="submit"
        >
          {t("Submit")}
        </button>
      </Spin>
     
    </Form>
  );
};
