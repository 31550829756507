import axios from "axios";
import { headers, baseUrl } from "./utils";

export async function listProducts(params) {
  return axios.get(`${baseUrl}/vendor/getallproduct`, { headers }, params).then((r) => r.data.data);
}

export async function deleteProduct(productId) {
  const user = JSON.parse(localStorage.getItem("user"));
  return axios
    .delete(`${baseUrl}/vendor/deleteproduct/` + productId,
      {
        headers: {
          Authorization: `Bearer ${user?.token}`, 

        },
      }
    )
    .then((r) => r.status === 200);
}

export async function createProduct(payload) {
  const user = JSON.parse(localStorage.getItem("user"));

  return axios.post(`${baseUrl}/vendor/createproduct`, payload, {
    headers: {
      Authorization: `Bearer ${user?.token}`, 
    },
  }).then((r) => r.data);
}
export async function editProduct(productId, payload) {
  const user = JSON.parse(localStorage.getItem("user"));
  return axios
    .patch(`${baseUrl}/vendor/updateproduct/`, payload, {
      headers: {
        Authorization: `Bearer ${user?.token}`
      },
    })
    .then((r) => r.data);
}

export async function createOrEditProduct({ mode, product_id, payload }) {
  switch (mode) {
    case "create":
      return createProduct(payload);
    case "edit":
      return editProduct(product_id, payload);
    default:
      return null;
  }
}

export async function getProductById(productId,) {
  const user = JSON.parse(localStorage.getItem("user"));
  return axios.get(`${baseUrl}/vendor/getbyidproduct/${productId}`, {
    headers: {
      Authorization: `Bearer ${user?.token}`, 
    },
  }).then((r) => r.data);
}
export async function getCurrentPlan() {
  const user = JSON.parse(localStorage.getItem("user"));
  return axios.get(`${baseUrl}/vendor/getcurrentplan`, {
    headers: {
      Authorization: `Bearer ${user?.token}`, 
    },
  }).then((r) => r.data);
}

export async function importBulkProduct(payload) {
  const user = JSON.parse(localStorage.getItem("user"));

  return axios.post(`${baseUrl}/vendor/uploadproductexcel`, payload, {
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  }).then((r) => r.data);
}