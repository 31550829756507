import {
  Alert,
  Button,
  Form,
  message,
  Modal,
  notification,
  Spin,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useImportProductExcel } from "../../../api/hooks/products";
import { Link } from "react-router-dom";

const DefaultErrorAlert = () => {
  return (
    <Alert
      type="error"
      message={
        <>
          An error has occurred. <br />
          Please try again or contact support if the problem persists.
        </>
      }
      closable
      style={{ marginBottom: 25 }}
    />
  );
};

export const ImportProductButton = () => {
  const { t, i18n } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [alert, setAlert] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const showModal = () => setModalOpen(true);
  const closeModal = () => {
    setModalOpen(false);
    form.resetFields();
    setAlert(null);
    setSelectedFile(null);
  };

  const onError = (error) => {
    if (error.response?.status === 400) {
      const errorMessage =
        error.response.data.errors[0]?.message || t("something_went_wrong");
      setAlert(
        <Alert
          message={errorMessage}
          type="error"
          style={{ marginBottom: 25 }}
          closable
        />
      );
    } else {
      setAlert(<DefaultErrorAlert />);
    }
  };

  const onSuccess = (response) => {
    if (response.status_code === 200) {
      notification.success({
        message: t("product_imported_successfully"),
      });
    } else {
      notification.error({
        message: t("something_went_wrong"),
      });
    }
    closeModal();
    queryClient
      .invalidateQueries({ queryKey: ["listProducts"] })
      .catch(() =>
        notification.error({ message: t("failed_reload_products") })
      );
  };

  const mutation = useImportProductExcel({
    onSuccess,
    onError,
  });

  const beforeUpload = (file) => {
    const isExcel =
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel";

    if (!isExcel) {
      message.error("Only Excel files (.xlsx, .xls) are allowed!");
      return Upload.LIST_IGNORE;
    }

    setSelectedFile(file);
    return false;
  };

  const handleUpload = () => {
    if (!selectedFile) {
      message.error("Please select an Excel file!");
      return;
    }

    const formData = new FormData();
    formData.append("product_excel_file", selectedFile);

    mutation.mutate(formData);
  };

  return (
    <>
      <button
        onClick={showModal}
        className="btn btn-primary rounded-md text-white"
        style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
      >
        <UploadOutlined className="mr-2" /> {t("all_product_Import")}
      </button>

      {/* <Modal
        width={800}
        title={t("import_product")}
        open={modalOpen}
        closable
        onCancel={closeModal}
        onOk={handleUpload}
        style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
      >
        <Form form={form}>
          <Spin spinning={mutation.isLoading}>
            {alert}
            <Upload
              beforeUpload={beforeUpload}
              showUploadList={true}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Select Excel File</Button>
            </Upload>
          </Spin>
        </Form>
      </Modal> */}

      <Modal
        // width={250}
        title={t("all_product_pro_imp")}
        open={modalOpen}
        closable
        onCancel={closeModal}
        footer={[
          <Button key="cancel" onClick={closeModal}>
            {t("cancel")}
          </Button>,
          // <Button key="download" type="default" onClick={handleDownloadTemplate}>
          //   {t("download_sample")}
          // </Button>,
          <Button key="upload" style={{backgroundColor:'#f4900c'}} type="primary" onClick={handleUpload}>
            {t("upload")}
          </Button>,
        ]}
        style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
      >
      

        {/* Description */}
        <p style={{ marginBottom: "15px", fontSize: "14px", color: "#555" }}>
        Upload an excel file in the same format as shown in sample
                  file
        </p>
        <Link
          to="/sample.xlsx"
          target="_blank"
          download
          className="text-primary"
        >
          {t("all_product_down")}
        </Link>
        {/* Upload Button */}
        <Upload
          beforeUpload={beforeUpload}
          showUploadList={true}
          maxCount={1}
          className="ml-4"
        >
          <Button icon={<UploadOutlined />}>Select Excel File</Button>
        </Upload>
      </Modal>
    </>
  );
};
