import React, { useState } from 'react';
import { Table, Space } from 'antd';
import { DeleteProductIcon } from "./DeleteProductIcon";
import { EditProductIcon } from "./EditProductIcon";
import { useTranslation } from 'react-i18next';
import { CreateProductButton } from './CreateProductButton';
import { ProductDetails } from './ProductDetails';
import { ImportProductButton } from './ImportProductButton';

export const ProductsTable = ({ products, loading, currentPlan, tags, colors, sizes, categories }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [selectedProductId, setSelectedProductId] = useState(null);

    const columns = [
        {
            title: t('ID'),
            key: 'id',
            dataIndex: 'product_id',
            defaultSortOrder: 'ascend',
            sortDirections: ['descend'],
            sorter: (a, b) => a.product_id - b.product_id,
            align: isArabic ? 'right' : 'left',
            width: 100,
            fixed: 'left'
        },
        {
            title: t('all_product_dataTable1'),
            key: 'name',
            dataIndex: isArabic ? 'product_name_ar' : 'product_name',
            sorter: (a, b) => a.product_name.localeCompare(b.product_name),
            align: isArabic ? 'right' : 'left',
            ellipsis: true,
        },
        {
            title: t('all_product_dataTable2'),
            key: 'price',
            dataIndex: 'product_price',
            align: isArabic ? 'right' : 'left',
            width: 120
        },
        {
            title: t('all_product_dataTable4'),
            key: 'status',
            dataIndex: 'status',
            align: isArabic ? 'right' : 'left',
            width: 120,
            render: (status) => (status === 1 ? t('Published') : t('Draft'))
        },
        {
            title: t('all_product_dataTable3'),
            key: 'category',
            dataIndex: isArabic ? 'category_name_ar' : 'category_name',
            align: isArabic ? 'right' : 'left',
            width: 150
        },
        {
            title: t('Description'),
            key: 'desc',
            dataIndex: isArabic ? 'product_description_ar' : 'product_description',
            align: isArabic ? 'right' : 'left',
            ellipsis: true,
            render: (desc) => desc?.replace(/<[^>]+>/g, ''),
        },
        {
            title: t('Actions'),
            key: 'actions',
            width: 120,
            align: 'center',
            fixed: 'right',
            render: (product) => (
                <Space
                    onClick={(e) => e.stopPropagation()}
                >
                    <DeleteProductIcon product={product} />
                    <EditProductIcon product={product} />
                </Space>
            )
        }
    ];

    return (
        <div style={{ direction: isArabic ? 'rtl' : 'ltr', padding: '1rem' }}>

            <div className="flex items-center justify-between">
                <h2 className="text-title text-sm md:text-base font-medium pb-2 roboto">
                    {t("all_product_title")}
                </h2>
                <div className="flex gap-2">
                    <CreateProductButton tags={tags} colors={colors} sizes={sizes} categories={categories} currentPlan={currentPlan} />
                    <ImportProductButton />
                </div>
            </div>
            <div className="w-full mx-auto p-4">
                <div className="bg-teal-400 text-black font-medium px-4 py-2 rounded-lg">
                    {t("all_product_limit")} <span className="font-bold">{products.length} / {currentPlan?.data?.data[0]?.product_limit}</span>
                </div>
            </div>
            <Table
                aria-label={t('Products')}
                rowKey="product_id"
                loading={loading}
                dataSource={products}
                columns={columns}
                pagination={{ pageSize: 10 }}
                bordered
                scroll={{ x: "max-content" }}
                style={{ boxShadow: '0 4px 10px rgba(0,0,0,0.1)', borderRadius: '8px', overflow: 'hidden' }}
                onRow={(record) => ({
                    onClick: () => setSelectedProductId(record.product_id)
                })}
            />
            {
                selectedProductId ? <ProductDetails productId={selectedProductId} /> : null
            }
        </div>
    );
};
