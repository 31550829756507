import { useMutation } from '@tanstack/react-query';
import { importBulkProduct } from "../../products";

export const useImportProductExcel = ({ onSuccess, onError }) => {
    return useMutation({
        mutationKey: ['importProduct'],
        mutationFn: importBulkProduct,
        onSuccess,
        onError
    })
}
