import React, { useEffect, useState } from 'react'
import CategoriesForm from './CategoriesForm'
import CategoriesTable from './CategoriesTable'
import { useCreateOrEditCategory, useListCategories } from '../../../api/hooks/categories';
import { Alert, Form, notification } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

function CategoriesManagement() {
    const { t } = useTranslation();
    const [getCategories, setCategories] = useState([]);
    const [editingCategory, setEditingCategory] = useState(null);
    const categoriesQuery = useListCategories({});
    const queryClient = useQueryClient();
    const [form] = Form.useForm();

    useEffect(() => {
        if (categoriesQuery.data) {
            setCategories(categoriesQuery.data);
        }
    }, [categoriesQuery.data]);

    const onErrorWrapper = (error) => {
        console.log(error);

    };

    const onSuccessWrapper = (response) => {
        if (response) {
            notification.success({
                message: response?.message, // Translated text
            });
        } else {
            notification.error({
                message: t("something_went_wrong"),
            });
        }
        queryClient
            .invalidateQueries({ queryKey: ["listCategories"] })
    };

    // listCategories
    const mutation = useCreateOrEditCategory({
        onSuccess: onSuccessWrapper,
        onError: onErrorWrapper,
    });

    const onFinish = (values) => {
        if (editingCategory) {
            mutation.mutate({ payload: { ...values, id: editingCategory.category_id }, mode: "edit" });
        } else {
            mutation.mutate({ payload: values, mode: "create" });
        }
        setEditingCategory(null); // Reset after save
        form.resetFields();
    };

    const onEdit = (category) => {
        setEditingCategory(category);
    };
    return (
        <div>
            <h2 className="text-title text-lg md:text-2xl uppercase">
                {t("vd_pc_Categories")}
            </h2>
            <div className="p-4 border border-gray-200 bg-white shadow-sm col-span-2 mt-5">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {/* Form takes 1/3 width */}
                    <div className="md:col-span-1">
                        <CategoriesForm form={form} onFinish={onFinish} editingCategory={editingCategory} setEditingCategory={setEditingCategory} />
                    </div>

                    {/* Table takes 2/3 width */}
                    <div className="md:col-span-2">
                        <CategoriesTable categoriesData={getCategories} loading={categoriesQuery.isLoading} onEdit={onEdit} />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CategoriesManagement
