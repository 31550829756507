import {useMutation} from '@tanstack/react-query';
import {deleteCategory } from "../../categories";

export const useDeleteCategory = ({  onSuccess, onError }) => {
    
    return useMutation({
        mutationKey: [ 'deleteCategory' ],
        mutationFn: (categoryId) => deleteCategory(categoryId),
        onSuccess,
        onError
   })
}
