import { useQuery } from "@tanstack/react-query"
import { listColors } from "../../colors"
import { notification } from "antd"

export const useListColors = ({ params }) => {
    const handleError = (err) => {
        notification.error({
            key: "listColorError",
            message: "Failed to retrieve colors list"
        })
    }

    return useQuery({
        queryKey: ["listColors", params],
        queryFn: () => listColors(params),
        onError: handleError,
    })
}
