import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import StoreInformationContext from "./contexts/StoreInformationContext";
import CustomerContext from "./contexts/CustomerContext";
import UserContext from "./contexts/UserContext";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import CartContext from "./contexts/CartContext";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import LangContext from "./contexts/translateContext";
import DomainContext from "./contexts/DomainContext";

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "ar", "fr"],
    fallbackLng: "en",
    debug: false,
    // Options for language detector
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Suspense>
      <BrowserRouter>
        <ToastContainer
          autoClose={1000}
          pauseOnHover={true}
          closeOnClick={true} />
        <DomainContext>
          <LangContext>
            <QueryClientProvider client={queryClient}>
              <UserContext>
                <StoreInformationContext>
                  <CustomerContext>
                    <CartContext>
                      <App />
                    </CartContext>
                  </CustomerContext>
                </StoreInformationContext>
              </UserContext>
            </QueryClientProvider>
          </LangContext>
        </DomainContext>
      </BrowserRouter>
    </Suspense>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
