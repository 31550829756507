import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
// import vendorDashboardRoutes from "./DashboardRoutes";
import getStoreDetails from "../../../hooks/getStoreDetails";
import { HiOutlineTemplate } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { MdReviews } from "react-icons/md";
import { RiShieldStarFill } from "react-icons/ri";
import { AiFillDollarCircle } from "react-icons/ai";
// route wise components
import Dashboard from "../../../pages/Dashboard/Dashboard";
import Customers from "../../../pages/Dashboard/Customer/Customers";
import queryIcon from "../../../assets/icons/query.svg";
import { BsGearFill } from "react-icons/bs";
import { BsFillMegaphoneFill } from "react-icons/bs";
import { MdDashboard } from "react-icons/md";
import { RiCoupon3Fill, RiShoppingBag2Fill } from "react-icons/ri";
import { FaUsers, FaClipboardList } from "react-icons/fa";
import logo_demo from "../../../assets/demo_logo.png";
import VendorFlow from "../../../pages/Dashboard/mainFlow/vendorFlow";
import i18next from "i18next";

const iconSize = 20;

const VendorSidebar = ({ sidebarToggle, side }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [storeInfo, setStoreInfo] = useState();
  useEffect(() => {
    const getStoreData = async () => {
      setLoading(true);
      const response = await getStoreDetails();
      if (response.status_code === 200) {
        setStoreInfo(response.data);
      }
      setLoading(false);
    };

    getStoreData();
  }, []);

  const vendorDashboardRoutes = [
    {
      title: `${t("Main")}`,
      icon: <BsFillMegaphoneFill size={20} />,
      path: "/vendor/main-flow/",
      component: <VendorFlow />,
    },
    {
      title: `${t("Dashboard")}`,
      icon: <MdDashboard size={20} />,
      path: "/vendor/dashboard/",
      component: <Dashboard />,
    },
    {
      title: `${t("Products")}`,
      icon: <RiShoppingBag2Fill size={iconSize} />,
      submenu: [
        {
          title: `${t("Products_List")}`,
          path: "/vendor/products",
          component: <hi>hi</hi>,
        },
        {
          title: `${t("Categories")}`,
          path: "/vendor/categories",
          component: <hi>hi</hi>,
        },
        {
          title: `${t("Tags")}`,
          path: "/vendor/tags",
          component: <hi>hi</hi>,
        },
        {
          title: `${t("Size")}`,
          path: "/vendor/size",
          component: <hi>hi</hi>,
        },
        {
          title: `${t("Color")}`,
          path: "/vendor/color",
          component: <hi>hi</hi>,
        },
      ],
    },
    {
      title: `${t("Coupons")}`,
      icon: <RiCoupon3Fill size={iconSize} />,
      path: "/vendor/coupons",
      component: <Customers />,
    },
    {
      title: `${t("Packages")}`,
      icon: <RiShieldStarFill size={iconSize} />,
      submenu: [
        {
          title: `${t("Upgrade")}`,
          path: "/vendor/packages",
          component: <hi>hi</hi>,
        },
        {
          title: `${t("Packages_Payments")}`,
          path: "/vendor/packages-payments",
          component: <hi>hi</hi>,
        },
      ],
    },
    {
      title: `${t("Customers")}`,
      icon: <FaUsers size={iconSize} />,
      path: "/vendor/customers",
      component: <Customers />,
    },
    {
      title: `${t("Orders")}`,
      icon: <FaClipboardList size={iconSize} />,
      submenu: [
        {
          title: `${t("All_Orders")}`,
          path: "/vendor/orders",
          component: <hi>Orders</hi>,
        },
        // {
        //   title: `${t("Add_New_Order")}`,
        //   path: '/vendor/add-order',
        //   component: <hi>Orders</hi>,
        // },
        {
          title: `${t("Return_Requested")}`,
          path: "/vendor/return-orders",
          component: <hi>Orders</hi>,
        },
      ],
    },
    {
      title: `${t("Earnings")}`,
      icon: <AiFillDollarCircle size={iconSize} />,
      submenu: [
        {
          title: `${t("Total_Earnings")}`,
          path: "/vendor/payouts",
          component: <hi>sales report</hi>,
        },
      ],
    },
    {
      title: `${t("Customer_Queries")}`,
      icon: (
        <img
          className="queryIcon"
          src={queryIcon}
          width={iconSize}
          height={iconSize}
          alt="query"
        />
      ),
      path: "/vendor/product-queries",
      component: "",
    },
    // {
    //   title: `${t("Review")}`,
    //   icon: <MdReviews size={iconSize} />,
    //   path: "/vendor/reviews",
    //   component: "",
    // },
    {
      title: `${t("Appearance")}`,
      icon: <HiOutlineTemplate size={iconSize} />,
      submenu: [
        {
          title: `${t("Themes")}`,
          path: "/vendor/themes",
          component: <hi>Themes</hi>,
        },
      ],
    },
    {
      title: `${t("All_Settings")}`,
      icon: <BsGearFill size={iconSize} />,
      submenu: [
        {
          title: `${t("Store_Settings")}`,
          path: "/vendor/settings/store-settings",
          component: "",
        },
        {
          title: `${t("Slider_Banner_Settings")}`,
          path: "/vendor/settings/attributes",
          component: "",
        },
        {
          title: `${t("Payment_Information")}`,
          path: "/vendor/settings/payment-methods",
          component: "",
        },
        {
          title: `${t("Shipping_Methods")}`,
          path: "/vendor/settings/shipping-methods",
          component: "",
        },
        {
          title: `${t("Domain_Settings")}`,
          path: "/vendor/settings/domain-settings",
          component: "",
        },
      ],
    },
  ];

  const selectLanguage = i18next.language;

  if (loading) {
    return;
  }

  return (
    <div
      id="sidebar"
      className={`drawer-side shadow-xl overflow-x-hidden duration-300 pb-12 md:pb-5 ${sidebarToggle && side
        }`}
      dir={selectLanguage === 'ar' ? 'rtl' : 'ltr'}
    >
      {/* {(sidebarToggle || selectLanguage !== "ar") && ( */}
      <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
      {/* )} */}
      <div
        className={`menu w-60 z-10 bg-base-100 flex flex-row text-base-content border-gray-200 px-3 ${selectLanguage === "ar" && !sidebarToggle && "vendorSidebar"
          } `}
      >
        <div className="text-xl text-center w-full py-4 border border-[#dee2e6] mb-2 rounded bg-gray-800 flex flex-col items-center justify-center">
          <Link to="/vendor/dashboard">
            {storeInfo?.store_logo ? (
              <img
                src={storeInfo?.store_logo}
                className="w-20 rounded-full text-center"
                alt=""
              />
            ) : (
              <img
                src={logo_demo}
                alt="company name"
                className="w-20 rounded-full text-center"
              />
            )}
          </Link>
          <p className="text-center text-white text-sm pt-3">
            {storeInfo?.store_name}
          </p>
          <p className="text-center text-white text-sm pt-1">
            {storeInfo?.store_id}
          </p>
        </div>
        <div className="w-full py-2 border rounded px-1 border-[#dee2e6]">
          {vendorDashboardRoutes.map((menu, i) => {
            if (menu?.submenu) {
              return (
                <div
                  key={i}
                  className={`parent-dropdown rounded py-0 collapse ${selectLanguage == "ar" ? "" : "collapse-arrow"
                    } mb-1 mx-2 text-xs font-semibold`}
                >
                  <input type="checkbox" className="peer" />
                  <div className="sidemenu-parent hover:bg-primary hover:text-white collapse-title flex gap-x-2 items-center rounded-md">
                    <span className="icon text-[#D2D2D2]">{menu.icon}</span>
                    <span className="pl-3 hover:bg-primary ">{menu.title}</span>
                  </div>

                  <div className="collapse-content p-0 text-primary-content peer-checked:text-secondary-content mt-1">
                    <ul className="w-full rounded-none">
                      {menu.submenu.map((submenu, i) => {
                        return (
                          <li key={i}>
                            <NavLink
                              className="rounded-md mb-1 hover:bg-primary hover:text-white duration-200 pl-8"
                              to={submenu.path}
                            >
                              {/* <AiOutlineArrowRight className="text-secondary" /> */}
                              {/* <div className="sidebar-circle"></div> */}
                              {submenu.title}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              );
            } else {
              return (
                <li
                  className="mb-1 mx-2 rounded-md text-xs font-semibold"
                  key={i}
                >
                  <NavLink
                    className="sidemenu hover:bg-primary hover:text-white rounded-md gap-x-5"
                    to={menu.path}
                  >
                    <span className="icon text-[#D2D2D2]">{menu.icon}</span>
                    {menu.title}
                  </NavLink>
                </li>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default VendorSidebar;
