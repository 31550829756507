import axios from "axios";
import { headers, baseUrl } from "./utils";

export async function listTags(params) {
  return axios.get(`${baseUrl}/vendor/getalltag`, { headers }, params).then((r) => r.data.data);
}

export async function deleteTag(tagId) {
  const user = JSON.parse(localStorage.getItem("user"));
  return axios
    .delete(`${baseUrl}/vendor/deletetag/${tagId}`, {
      headers: {
        Authorization: `Bearer ${user?.token}`, // Set Authorization header
      },
    })
    .then((r) => r.status === 200);
}

export async function createTag(payload) {
  const user = JSON.parse(localStorage.getItem("user"));
  return axios.post(`${baseUrl}/vendor/createtag`, payload, {
    headers: {
      Authorization: `Bearer ${user?.token}`, // Set Authorization header
    },
  }).then((r) => r.data);
}

export async function editTag(tagId, payload) {
  const user = JSON.parse(localStorage.getItem("user"));
  return axios
    .patch(`${baseUrl}/vendor/updatetag/`, payload, {
      headers: {
        Authorization: `Bearer ${user?.token}`, // Set Authorization header
      },
    })
    .then((r) => r.data);
}

export async function createOrEditTag({ mode, tagId, payload }) {
  switch (mode) {
    case "create":
      return createTag(payload);
    case "edit":
      return editTag(tagId, payload);
    default:
      return null;
  }
}

export async function getTagById(tagId) {
  return axios.get(`${baseUrl}/vendor/tags/${tagId}`).then((r) => r.data);
}
