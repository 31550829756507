import { useQuery } from '@tanstack/react-query';
import {getCurrentPlan, getProductById} from "../../products";
import { notification } from 'antd';

export const useFetchCurrentPlan = () => {
    const handleError = (err) => {
        notification.error({
            key: 'getCurrentPlanError',
            message: 'Failed to retrieve current plan'
        })
    }

    return useQuery({
       queryKey: [ 'getCurrntPlan'],
       queryFn: () => getCurrentPlan(),
       onError: handleError
   })
}
