import axios from "axios";
import { headers, baseUrl } from "./utils";

export async function listCategories(params) {
  return axios.get(`${baseUrl}/vendor/getallcategory`, { headers }, params).then((r) => r.data.data);
}

export async function deleteCategory(CategoryId) {
  const user = JSON.parse(localStorage.getItem("user"));
  return axios
    .delete(`${baseUrl}/vendor/deletecategory/${CategoryId}`, {
      headers: {
        Authorization: `Bearer ${user?.token}`, // Set Authorization header
      },
    })
    .then((r) => r.status === 200);
}

export async function createCategory(payload) {
  const user = JSON.parse(localStorage.getItem("user"));
  return axios.post(`${baseUrl}/vendor/createcategory`, payload, {
    headers: {
      Authorization: `Bearer ${user?.token}`, // Set Authorization header
    },
  }).then((r) => r.data);
}

export async function editCategory(CategoryId, payload) {
  const user = JSON.parse(localStorage.getItem("user"));
  return axios
    .patch(`${baseUrl}/vendor/updatecategory`, payload, {
      headers: {
        Authorization: `Bearer ${user?.token}`, // Set Authorization header
      },
    })
    .then((r) => r.data);
}

export async function createOrEditCategory({ mode, CategoryId, payload }) {
  switch (mode) {
    case "create":
      return createCategory(payload);
    case "edit":
      return editCategory(CategoryId, payload);
    default:
      return null;
  }
}

export async function getCategoryById(CategoryId) {
  return axios.get(`${baseUrl}/vendor/categories/${CategoryId}`).then((r) => r.data);
}
