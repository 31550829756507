import React, { useEffect, useState } from "react";
import { message, Modal, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { baseUrl } from "../api/utils";

export const ImageUpload = ({
  value,
  onChange,
  fieldName,
  maxCount = 1,
  multiple = false,
  mimeType = ["image/png", "image/jpeg"],
  maxFileSize = 5 * 1024,
  action = `${baseUrl}/vendor/uploadfiles`,
}) => {
  const [fileList, setFileList] = useState([]);
  const [previewUrl, setPreviewUrl] = useState(null);

  useEffect(() => {
    const normalizedValue = Array.isArray(value) ? value :
      (value ? [value] : []);

    setFileList(normalizedValue);
  }, [value]);

  const handleChange = ({ fileList: newFileList, file, event }) => {
    // Always process the full list, including removed items
    const processedFileList = newFileList.map(file => {
      if (file.response) {
        const serverUrl = file.response.files?.[0]?.location;
        return {
          ...file,
          url: serverUrl || file.url,
        };
      }
      return file;
    }).filter(file =>
      file.status === 'done' || file.status === 'uploading' || file.status === 'error'
    );

    // For multiple uploads, preserve existing files with new uploads
    let finalFileList;
    if (multiple) {
      // Keep existing and new files, respecting maxCount
      finalFileList = processedFileList.slice(-maxCount);
    } else {
      // For single upload, replace entirely
      finalFileList = processedFileList.slice(-maxCount);
    }

    setFileList(finalFileList);

    if (onChange) {
      onChange(finalFileList);
    }
  };

  const beforeUpload = (file) => {
    const isValidType = mimeType.includes(file.type);
    const isValidSize = file.size / 1024 < maxFileSize;

    if (!isValidType) {
      message.error(`Only ${mimeType.join(', ')} file types are allowed`);
      return Upload.LIST_IGNORE;
    }

    if (!isValidSize) {
      message.error(`File must be smaller than ${maxFileSize}KB`);
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewUrl(file.url || file.preview);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Upload
        name="files"
        action={action}
        listType="picture-card"
        fileList={fileList}
        onPreview={onPreview}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        multiple={multiple}
        maxCount={maxCount}
        id={fieldName}
      >
        {fileList.length < maxCount && uploadButton}
      </Upload>
      <Modal
        open={!!previewUrl}
        title="Image Preview"
        footer={null}
        onCancel={() => setPreviewUrl(null)}
        zIndex={1500}
      >
        {previewUrl && (
          <img
            alt="preview"
            style={{ width: "100%" }}
            src={previewUrl}
            className="ant-image-img"
          />
        )}
      </Modal>
    </>
  );
};