import React, { useEffect, useState } from 'react'
import { Alert,  Form,  notification } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import ColorsForm from './ColorsForm';
import ColorsTable from './ColorsTable';
import { useListColors } from '../../../api/hooks/colors/useListColors';
import { useCreateOrEditColor } from '../../../api/hooks/colors';

function ColorsManagement() {
    const { t } = useTranslation();
    const [getColors, setColors] = useState([]);
    const [editingColors, setEditingColors] = useState(null);
    const colorsQuery = useListColors({});
    const queryClient = useQueryClient();
    const [form] = Form.useForm();

    useEffect(() => {
        if (colorsQuery.data) {
            setColors(colorsQuery.data);
        }
    }, [colorsQuery.data]);

    const onErrorWrapper = (error) => {
        console.log(error);

    };

    const onSuccessWrapper = (response) => {
        if (response) {
            notification.success({
                message: response?.message, // Translated text
            });
        } else {
            notification.error({
                message: t("something_went_wrong"),
            });
        }
        queryClient
            .invalidateQueries({ queryKey: ["listColors"] })
    };

    // listCategories
    const mutation = useCreateOrEditColor({
        onSuccess: onSuccessWrapper,
        onError: onErrorWrapper,
    });

    const onFinish = (values) => {
        if (editingColors) {
            
            mutation.mutate({ payload: { ...values, id: editingColors.color_id }, mode: "edit" });
        } else {
            mutation.mutate({ payload: values, mode: "create" });
        }
        setEditingColors(null); // Reset after save
        form.resetFields(); 
    };

    const onEdit = (color) => {
        setEditingColors(color);
    };
    return (
        <div>
            <h2 className="text-title text-lg md:text-2xl uppercase">
                {t("vd_pc_Colors")}
            </h2>
            <div className="p-4 border border-gray-200 bg-white shadow-sm col-span-2 mt-5">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {/* Form takes 1/3 width */}
                    <div className="md:col-span-1">
                        <ColorsForm form={form} onFinish={onFinish} editingColors={editingColors} setEditingColors={setEditingColors} />
                    </div>

                    {/* Table takes 2/3 width */}
                    <div className="md:col-span-2">
                        <ColorsTable colorsData={getColors} loading={colorsQuery.isLoading} onEdit={onEdit} />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ColorsManagement
