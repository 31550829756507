import axios from "axios";
import { headers, baseUrl } from "./utils";

export async function listSizes(params) {
  return axios.get(`${baseUrl}/vendor/getallsize`, { headers }, params).then((r) => r.data.data);
}

export async function deleteSize(sizeId) {
  const user = JSON.parse(localStorage.getItem("user"));
  return axios
    .delete(`${baseUrl}/vendor/deletesize/${sizeId}`, {
      headers: {
        Authorization: `Bearer ${user?.token}`, // Set Authorization header
      },
    })
    .then((r) => r.status === 200);
}

export async function createSize(payload) {
  const user = JSON.parse(localStorage.getItem("user"));
  return axios.post(`${baseUrl}/vendor/createsize`, payload, {
    headers: {
      Authorization: `Bearer ${user?.token}`, // Set Authorization header
    },
  }).then((r) => r.data);
}

export async function editSize(payload) {
  const user = JSON.parse(localStorage.getItem("user"));
  return axios
    .patch(`${baseUrl}/vendor/updatesize`, payload, {
      headers: {
        Authorization: `Bearer ${user?.token}`, // Set Authorization header
      },
    })
    .then((r) => r.data);
}

export async function createOrEditSize({ mode, payload }) {
  switch (mode) {
    case "create":
      return createSize(payload);
    case "edit":
      return editSize(payload);
    default:
      return null;
  }
}

export async function getSizeById(sizeId) {
  return axios.get(`${baseUrl}/vendor/sizes/${sizeId}`).then((r) => r.data);
}
