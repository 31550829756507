import { useQuery } from "@tanstack/react-query"
import { listTags } from "../../tags"
import { notification } from "antd"

export const useListTags = ({ params }) => {
    const handleError = (err) => {
        notification.error({
            key: "listTagError",
            message: "Failed to retrieve Tag list"
        })
    }

    return useQuery({
        queryKey: ["listTags", params],
        queryFn: () => listTags(params),
        onError: handleError,
    })
}
