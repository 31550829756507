import { Helmet, HelmetProvider } from "react-helmet-async";
import { Routes, Route } from "react-router";
import ScrollToTop from "./components/utils/ScrollToTop";
import Dashboard from "./pages/Dashboard/Dashboard";
import DashboardLayout from "./pages/DashboardLayout/DashboardLayout";
import HomePage from "./pages/HomePage";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Customers from "./pages/Dashboard/Customer/Customers";
import Invoices from "./pages/Dashboard/Billing/Billing";
import AddInvoice from "./pages/Dashboard/Billing/AddInvoice";
import AllOrders from "./pages/Dashboard/Orders/AllOrders";
import AllProducts from "./pages/Dashboard/AllProducts/AllProducts";
import { ProductManagement } from "./modules/products/components/ProductManagement";
import AddProducts from "./pages/Dashboard/AddProducts/AddProducts";
import Categories from "./pages/Dashboard/Categories/Categories";
import Tags from "./pages/Dashboard/Tags/Tags";
import Settings from "./pages/Dashboard/Settings/StoreSettings";
import AddUser from "./pages/Dashboard/Stuffs/AddStuff";
import UserLayout from "./pages/User/UserLayout";
import Profile from "./pages/User/Profile";
import ChangePassword from "./pages/User/ChangePassword/ChangePassword";
import Orders from "./pages/User/Orders/Orders";

import AdminDashboard from "./pages/Admin/AdminDashboard";
import { AddInvoice as AdminInvoice } from "./pages/Admin/Billing/AddInvoice";
import Vendors from "./pages/Admin/Vendors/Vendors";
import AddVendor from "./pages/Admin/Vendors/AddVendor";
import EditVendor from "./pages/Admin/Vendors/EditVendor";
import AddQuote from "./pages/Admin/Quotes/AddQuote";
import EditQuote from "./pages/Admin/Quotes/EditQuote";
import Tickets from "./pages/Admin/Tickets/Tickets";
import AddTicket from "./pages/Admin/Tickets/AddTicket";
import EditTicket from "./pages/Admin/Tickets/EditTicket";
import Contacts from "./pages/Admin/Contacts/Contacts";
import AddContact from "./pages/Admin/Contacts/AddContact";
import EditContact from "./pages/Admin/Contacts/EditContact";
import Events from "./pages/Admin/Events/Events";
import EditInvoice from "./pages/Admin/Billing/EditInvoice";
import AddEvent from "./pages/Admin/Events/AddEvent";
import EditEvent from "./pages/Admin/Events/EditEvent";
import Users from "./pages/Admin/Users/Users";
import EditUser from "./pages/Admin/Users/EditUser";
import AdminLayout from "./pages/Admin/AdminLayout";
import PaymentMethods from "./pages/Dashboard/Settings/PaymentMethods";
import ShippingMethods from "./pages/Dashboard/Settings/ShippingMethods";
import AddOrder from "./pages/Dashboard/Orders/AddOrder";
import AddCustomer from "./pages/Dashboard/Customer/AddCustomer";
import ReturnOrders from "./pages/Dashboard/Orders/ReturnOrders";
import TechnicalSupport from "./pages/Dashboard/TechnicalSupport/TechnicalSupport";
import AdminCustomers from "./pages/Admin/Customers/Customers";
import AdminAddCustomer from "./pages/Admin/Customers/AdminAddCustomers";
import Packages from "./pages/Dashboard/Packages/Packages";
import AllCoupon from "./pages/Dashboard/Coupon/AllCoupon";
import AddCoupon from "./pages/Dashboard/Coupon/AddCoupon";
import Payout from "./pages/Dashboard/Payout/Payout";
import PayoutSettings from "./pages/Dashboard/Payout/PayoutSettings";
import PayoutRequest from "./pages/Dashboard/Payout/PayoutRequest";
import Reviews from "./pages/Dashboard/Reviews/Reviews";
import CustomerQueries from "./pages/Dashboard/ProductQueries/ProductQueries";
import PackagePayments from "./pages/Dashboard/Packages/PackagePayments";
import UploadedFiles from "./pages/Dashboard/UploadedFiles/UploadedFiles";
import VendorProfile from "./pages/Dashboard/VendorProdile/VendorProfile";
import AdminOrders from "./pages/Admin/Orders/Orders";
import AllPlans from "./pages/Admin/Plan/AllPlans";
import AdminCreatePlan from "./pages/Admin/Plan/AdminCreatePlan";
import RequestVendors from "./pages/Admin/Customers/RequestCustomers";
import SuspendedVendors from "./pages/Admin/Customers/SuspendedCustomers";
import AllDomain from "./pages/Admin/Domain/AllDomain";
import CustomDomain from "./pages/Admin/Domain/CustomDomain";
import AllPages from "./pages/Admin/Pages/AllPages";
import CreatePage from "./pages/Admin/Pages/CreatePage";
import AdminCreateOrder from "./pages/Admin/Orders/AdminCreateOrder";
import PrivateRoute from "./components/utils/PrivateRoute";
import Size from "./pages/Dashboard/Size/Size";
import Color from "./pages/Dashboard/Color/Color";
import Attribute from "./pages/Dashboard/Attribute/Attribute";
import VendorViewSingleProduct from "./pages/Dashboard/Products/VendorViewSingleProduct";
import EditCustomer from "./pages/Dashboard/Customer/EditCustomer";
import VendorForgetPass from "./pages/VendorForgetPass/VendorForgetPass";
import VendorResetPass from "./pages/VendorForgetPass/VendorResetPass";
import AddOrderCheckout from "./pages/Dashboard/Orders/AddOrderCheckout";
import StoreSettings from "./pages/Dashboard/Settings/StoreSettings";
import AllStuffs from "./pages/Dashboard/Stuffs/AllStuffs";
import AddStuff from "./pages/Dashboard/Stuffs/AddStuff";
import EditStuff from "./pages/Dashboard/Stuffs/EditStuff";
import AllRoles from "./pages/Admin/RoleAndRoleAccess/AllRoles";
import AddRoles from "./pages/Admin/RoleAndRoleAccess/AddRoles";
import EditRoles from "./pages/Admin/RoleAndRoleAccess/EditRoles";
import DomainSettings from "./pages/Dashboard/Settings/DomainSettings";
import StoreHome from "./pages/StoreTemplate/StoreHome";
import StoreCart from "./pages/StoreTemplate/StoreCart";
import StoreCheckout from "./pages/StoreTemplate/StoreCheckout";
import StorePayment from "./pages/StoreTemplate/StorePayment";
import AddPaymentMethods from "./pages/Dashboard/Settings/AddPaymentMethods";
import AddShippingMethods from "./pages/Dashboard/Settings/AddShippingMethods";
import AdminLogin from "./pages/Admin/AdminLogin/AdminLogin";
import RequireAdmin from "./pages/Admin/AdminLogin/RequireAdmin";
import AdminEditPlan from "./pages/Admin/Plan/AdminEditPlan";
import EditPage from "./pages/Admin/Pages/EditPage";
import GeneralSettings from "./pages/Admin/GeneralSettings.js/GeneralSettings";
import AdminPaymentMethods from "./pages/Admin/AdminPaymentMethods/AdminPaymentMethods";
import AdminAddPaymentMethods from "./pages/Admin/AdminPaymentMethods/AdminAddPaymentMethods";
import AdminEditPaymentMethods from "./pages/Admin/AdminPaymentMethods/AdminEditPaymentMethods";
import CreateLanguage from "./pages/Admin/Language/CreateLanguage";
import ManageLanguage from "./pages/Admin/Language/ManageLanguage";
import EditLanguage from "./pages/Admin/Language/EditLanguage";
import TrackOrder from "./pages/StoreTemplate/TrackOrder";
import ContactUs from "./pages/StoreTemplate/StoreContact";
import Shop from "./pages/StoreTemplate/Shop";
import RegisterLogin from "./pages/StoreTemplate/RegisterLogin";
import ProductDetails from "./pages/StoreTemplate/ProductDetails";
import StoreThankYou from "./pages/StoreTemplate/StoreThankYou";
import OrderDetails from "./pages/User/Orders/OrderDetails";
import { useContext, useEffect, useState } from "react";
import UpdateCoupon from "./pages/Dashboard/Coupon/UpdateCoupon";
import EditCustomerQuery from "./pages/Dashboard/ProductQueries/EditCustomerQuery";
import GeneralQuery from "./pages/Admin/GeneralQuery/GeneralQuery";
import UpdateQuery from "./pages/Admin/GeneralQuery/UpdateQuery";
import GeneralServices from "./pages/Admin/GeneralServices/GeneralServices";
import AddServices from "./pages/Admin/GeneralServices/AddServices";
import EditServices from "./pages/Admin/GeneralServices/EditServices";
import AdminProfile from "./pages/Admin/AdminProdile/AdminProfile";
import UserForgetPass from "./pages/User/UserForget/UserForgetPass";
import UserResetPass from "./pages/User/UserForget/UserResetPass";
import Verification from "./components/utils/Verification";
import VerifyPage from "./components/utils/VerifyPage";
import CustomerVerification from "./components/utils/CustomerVerification";
import CustomerEmailVerify from "./components/utils/CustomerEmailVerify";
import ResendEmail from "./pages/User/UserForget/ResendEmail";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TrackPage from "./pages/StoreTemplate/TrackPage";
import TrackPageVendor from "./pages/StoreTemplate/TrackPageVendor";
import MoyaserForPackageUp from "./pages/StoreTemplate/StoreComponents/MoyaserForPackageUp";
import ThankyouUp from "./pages/StoreTemplate/StoreComponents/ThankyouUp";
import UpLoadedFileAdmin from "./pages/Dashboard/UploadedFiles/UpLoadedFileAdmin";
import RazorpayPayment from "./pages/StoreTemplate/StoreComponents/RazorpayPayment";
import Themes from "./pages/Dashboard/Settings/Apperiance/Themes";
import PlansPricing from "./pages/Plans_pricing";
import AboutUs from "./pages/AboutUs";
import Agrement from "./pages/Agrement";
import Help from "./pages/Help";
import VendorFlow from "./pages/Dashboard/mainFlow/vendorFlow";

import i18next from "i18next";
import cookies from "js-cookie";
import languages from "./locales/Languages";
import { useTranslation } from "react-i18next";
import Guide from "./pages/Guide/Guide";
import Editproduct from "./pages/Dashboard/EditProduct/EditProduct";
import { domainProvider } from "./contexts/DomainContext";
import GuideDashboard from "./pages/Guide/GuideDashboard";
import CreateStore from "./pages/Guide/CreateStore";
import ProductList from "./pages/Guide/GuideProduct/ProductList";
import GuideAddProduct from "./pages/Guide/GuideProduct/GuideAddProduct";
import ManageCategory from "./pages/Guide/GuideProduct/ManageCategory";
import GuideOrders from "./pages/Guide/GuideOrders";
import GuideCoupons from "./pages/Guide/GuideCoupon";
import GuideSlider from "./pages/Guide/GuideSlider";
import AdminEditCustomer from "./pages/Admin/Customers/EditCustomer";
import CategoriesManagement from "./modules/categories/components/CategoriesManagement";
import SizesManagement from "./modules/sizes/components/SizesManagement";
import TagsManagement from "./modules/tags/components/TagsManagement";
import ColorsManagement from "./modules/colors/components/ColorsManagement";

function App() {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();
  let selectLanguage = i18next.language;
  useEffect(() => {}, [currentLanguage, t]);

  const [ganarel, setGanarel] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_APIURL}/admin/getgeneraldetails`)
      .then((res) => res.json())
      .then((data) => {
        setGanarel(data.data[0]);
      });
  }, []);

  // custom domain
  const { customdomain, titleName } = useContext(domainProvider);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Homepage - {titleName}</title>
        </Helmet>
        {/* {customdomain === "aitech.com.sa" ? ( */}
        {ganarel?.favicon && <link rel="icon" href={ganarel.favicon} />}
        {/* ) : (
          <link rel="icon" href="../public/favicon.ico" />
        )} */}
        <div
          className="App font-Roboto"
          style={{ direction: `${selectLanguage === "ar" ? "rtl" : "ltr"}` }}
        >
          <ScrollToTop>
            <Routes>
              {customdomain === "aitech.com.sa" && (
                <Route path="/" element={<HomePage />}></Route>
              )}
              {customdomain === "aitech.com.sa" ? (
                <Route path="/:store_username" element={<StoreHome />}></Route>
              ) : (
                <Route path="/" element={<StoreHome />}></Route>
              )}
              <Route path="/pay" element={<RazorpayPayment />}></Route>
              <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
              <Route path="/Agrement" element={<Agrement />}></Route>
              <Route path="/help" element={<Help />}></Route>
              <Route path="/plans-pricing" element={<PlansPricing />}></Route>
              <Route path="/about-us" element={<AboutUs />}></Route>
              <Route
                path="/how-to-create-new-online-store-with-AiTech/"
                element={<Guide />}
              >
                <Route path="dashboard" element={<GuideDashboard />} />
                <Route path="create-store" element={<CreateStore />} />
                <Route path="product/">
                  <Route path="list" element={<ProductList />} />
                  <Route path="add" element={<GuideAddProduct />} />
                  <Route path="manage" element={<ManageCategory />} />
                </Route>
                <Route path="orders" element={<GuideOrders />} />
                <Route path="coupons" element={<GuideCoupons />} />
                <Route path="slider" element={<GuideSlider />} />
              </Route>

              {/* authentication routes start */}
              <Route path="/vendor/login" element={<Login />}></Route>
              <Route
                path="/vendor/forget-pass"
                element={<VendorForgetPass />}
              ></Route>
              <Route
                path="/vendor/reset_password"
                element={<VendorResetPass />}
              ></Route>
              <Route path="/vendor/register" element={<Register />}></Route>
              <Route
                path="/vendor/verify-email"
                element={<Verification />}
              ></Route>
              <Route
                path="/vendor/verify_email"
                element={<VerifyPage />}
              ></Route>
              <Route path="/admin/login" element={<AdminLogin />}></Route>
              {/* authentication routes end */}

              {/* store routes start */}
              <Route
                path={`${
                  customdomain === "aitech.com.sa"
                    ? "/:store_username/product_details/:id"
                    : "/product_details/:id"
                }`}
                element={<ProductDetails />}
              ></Route>
              <Route
                path={`${
                  customdomain === "aitech.com.sa"
                    ? "/:store_username/shop"
                    : "/shop"
                }`}
                element={<Shop />}
              ></Route>
              <Route
                path={`${
                  customdomain === "aitech.com.sa"
                    ? "/:store_username/payment-thankyou"
                    : "/payment-thankyou"
                }`}
                element={<StoreThankYou />}
              ></Route>
              <Route
                path={`${
                  customdomain === "aitech.com.sa"
                    ? "/:store_username/register-login"
                    : "/register-login"
                }`}
                element={<RegisterLogin />}
              ></Route>
              <Route
                path={`${
                  customdomain === "aitech.com.sa"
                    ? "/:store_username/forgetPass"
                    : "/forgetPass"
                }`}
                element={<UserForgetPass />}
              ></Route>
              <Route
                path={`${
                  customdomain === "aitech.com.sa"
                    ? "/:store_username/customer/verify-email"
                    : "/customer/verify-email"
                }`}
                element={<CustomerVerification />}
              ></Route>
              <Route
                path={`${
                  customdomain === "aitech.com.sa"
                    ? "/:store_username/customer/verify_email"
                    : "/customer/verify_email"
                }`}
                element={<CustomerEmailVerify />}
              ></Route>
              <Route
                path={`${
                  customdomain === "aitech.com.sa"
                    ? "/:store_username/customer/resend-verification-email"
                    : "/customer/resend-verification-email"
                }`}
                element={<ResendEmail />}
              ></Route>
              <Route
                path={`${
                  customdomain === "aitech.com.sa"
                    ? "/:store_username/customer/reset_password"
                    : "/customer/reset_password"
                }`}
                element={<UserResetPass />}
              ></Route>
              <Route
                path={`${
                  customdomain === "aitech.com.sa"
                    ? "/:store_username/track_order"
                    : "/track_order"
                }`}
                element={<TrackOrder />}
              ></Route>
              <Route
                path={`${
                  customdomain === "aitech.com.sa"
                    ? "/:store_username/tracking_page"
                    : "/tracking_page"
                }`}
                element={<TrackPage />}
              ></Route>
              <Route
                path={`${
                  customdomain === "aitech.com.sa"
                    ? "/:store_username/cart"
                    : "/cart"
                }`}
                element={<StoreCart />}
              ></Route>
              <Route
                path={`${
                  customdomain === "aitech.com.sa"
                    ? "/:store_username/contact_us"
                    : "/contact_us"
                }`}
                element={<ContactUs />}
              ></Route>
              <Route
                path={`${
                  customdomain === "aitech.com.sa"
                    ? "/:store_username/payment"
                    : "/payment"
                }`}
                element={<StorePayment />}
              ></Route>
              <Route
                path={`${
                  customdomain === "aitech.com.sa"
                    ? "/:store_username/checkout"
                    : "/checkout"
                }`}
                element={<StoreCheckout />}
              ></Route>
              <Route
                path={`${
                  customdomain === "aitech.com.sa"
                    ? "/:store_username/user"
                    : "/user"
                }`}
                element={<UserLayout />}
              >
                <Route path="profile" element={<Profile />}></Route>
                <Route path="orders" element={<Orders />}></Route>
                <Route
                  path="change-password"
                  element={<ChangePassword />}
                ></Route>
                <Route
                  path="orders/order_details/:id"
                  element={<OrderDetails />}
                ></Route>
              </Route>
              {/* store routes end */}

              {/* vendor routes start */}
              <Route
                path="/vendor/"
                element={
                  <PrivateRoute>
                    <DashboardLayout />
                  </PrivateRoute>
                }
              >
                <Route path="main-flow" element={<VendorFlow />} />
                <Route path="dashboard" element={<Dashboard />}></Route>
                <Route path="products" element={<ProductManagement />}></Route>
                <Route path="categories" element={<CategoriesManagement />}></Route>
                <Route path="size" element={<SizesManagement />}></Route>
                <Route path="color" element={<ColorsManagement />}></Route>
                <Route path="tags" element={<TagsManagement />}></Route>
                <Route
                  path="product/:id"
                  element={<VendorViewSingleProduct />}
                ></Route>
                <Route path="product/edit" element={<Editproduct />}></Route>
                <Route path="attributes" element={<Attribute />}></Route>
                <Route path="coupons" element={<AllCoupon />}></Route>
                <Route path="add-coupon" element={<AddCoupon />}></Route>
                <Route path="edit_coupon" element={<UpdateCoupon />}></Route>
                <Route path="profile" element={<VendorProfile />}></Route>
                <Route path="add-customer" element={<AddCustomer />}></Route>
                <Route path="customers" element={<Customers />}></Route>
                <Route
                  path="customer/edit/:id"
                  element={<EditCustomer />}
                ></Route>
                <Route path="orders" element={<AllOrders />}></Route>
                <Route
                  path="orders/track-order"
                  element={<TrackPageVendor />}
                ></Route>
                <Route
                  path="orders/order_details/:id"
                  element={<OrderDetails />}
                ></Route>
                <Route path="return-orders" element={<ReturnOrders />}></Route>

                <Route path="packages" element={<Packages />}></Route>
                <Route
                  path="packages/upgrade-package"
                  element={<MoyaserForPackageUp />}
                ></Route>
                <Route
                  path="packages/upgrade-package/thankyou"
                  element={<ThankyouUp />}
                ></Route>
                <Route
                  path="packages-payments"
                  element={<PackagePayments />}
                ></Route>
                <Route path="themes" element={<Themes />}></Route>
                <Route path="reviews" element={<Reviews />}></Route>
                <Route
                  path="product-queries"
                  element={<CustomerQueries />}
                ></Route>
                <Route
                  path="update_queries"
                  element={<EditCustomerQuery />}
                ></Route>
                <Route path="payouts" element={<Payout />}></Route>
                <Route
                  path="payout-requests"
                  element={<PayoutRequest />}
                ></Route>
                <Route
                  path="payout-settings"
                  element={<PayoutSettings />}
                ></Route>

                {/* Stuff and Roles */}
                <Route path="staffs" element={<AllStuffs />}></Route>
                <Route path="add-staff" element={<AddStuff />}></Route>
                <Route path="edit-staff/:id" element={<EditStuff />}></Route>

                <Route
                  path="settings/store-settings"
                  element={<StoreSettings />}
                ></Route>
                <Route
                  path="settings/attributes"
                  element={<Attribute />}
                ></Route>
                <Route
                  path="settings/payment-methods"
                  element={<PaymentMethods />}
                ></Route>
                <Route
                  path="settings/payment-methods/add"
                  element={<AddPaymentMethods />}
                ></Route>
                <Route
                  path="settings/shipping-methods/add"
                  element={<AddShippingMethods />}
                ></Route>
                <Route
                  path="settings/shipping-methods"
                  element={<ShippingMethods />}
                ></Route>
                <Route
                  path="settings/domain-settings"
                  element={<DomainSettings />}
                ></Route>

                <Route path="add-invoice" element={<AddInvoice />}></Route>
                <Route path="add-order" element={<AddOrder />}></Route>
                <Route path="checkout" element={<AddOrderCheckout />}></Route>
                <Route
                  path="uploaded-files"
                  element={<UploadedFiles />}
                ></Route>
                <Route
                  path="technical-support"
                  element={<TechnicalSupport />}
                ></Route>
                <Route path="*" element={<span>Not Found</span>}></Route>
              </Route>
              {/* vendor routes end */}

              {/* admin routes start */}
              <Route
                path="/admin/"
                element={
                  <RequireAdmin>
                    <AdminLayout />
                  </RequireAdmin>
                }
              >
                <Route index element={<AdminDashboard />}></Route>
                <Route path="dashboard" element={<AdminDashboard />}></Route>
              
                <Route path="profile" element={<AdminProfile />}></Route>
                <Route path="orders" element={<AdminOrders />}></Route>
                <Route
                  path="create-order"
                  element={<AdminCreateOrder />}
                ></Route>

                {/* plans */}
                <Route path="plan/all-plans" element={<AllPlans />}></Route>
                <Route path="plan/create" element={<AdminCreatePlan />}></Route>
                <Route path="plan/edit/:id" element={<AdminEditPlan />}></Route>

                <Route path="customers" element={<AdminCustomers />}></Route>
                <Route
                  path="add-customer"
                  element={<AdminAddCustomer />}
                ></Route>
                <Route
                  path="requested-customers"
                  element={<RequestVendors />}
                ></Route>
                <Route
                  path="suspended-customers"
                  element={<SuspendedVendors />}
                ></Route>
                 <Route path="customer/edit/:id" element={<AdminEditCustomer />}></Route>

                <Route path="edit-event" element={<EditEvent />}></Route>
                <Route path="vendors" element={<Vendors />}></Route>
                <Route path="add-vendor" element={<AddVendor />}></Route>
                <Route path="vendor/edit/:id" element={<EditVendor />}></Route>

                {/* Templates */}
                <Route path="templates" element={<UpLoadedFileAdmin />}></Route>

                {/* Domains */}
                <Route path="domain/" element={<AllDomain />}></Route>
                <Route
                  path="domain/custom-domain"
                  element={<CustomDomain />}
                ></Route>

                {/* general query  */}
                <Route path="general_query" element={<GeneralQuery />}></Route>
                <Route path="update_query" element={<UpdateQuery />}></Route>

                {/* payment Gateway */}
                <Route
                  path="payment-gateway"
                  element={<AdminPaymentMethods />}
                ></Route>
                <Route
                  path="payment-gateway/add"
                  element={<AdminAddPaymentMethods />}
                ></Route>
                <Route
                  path="payment-gateway/edit/:id"
                  element={<AdminEditPaymentMethods />}
                ></Route>

                {/* pages */}
                <Route path="page/create" element={<CreatePage />}></Route>
                <Route path="page/all-pages" element={<AllPages />}></Route>
                <Route path="page/edit/:id" element={<EditPage />}></Route>

                {/* languages */}
                <Route
                  path="language/create"
                  element={<CreateLanguage />}
                ></Route>
                <Route
                  path="language/manage"
                  element={<ManageLanguage />}
                ></Route>
                <Route
                  path="language/edit/:id"
                  element={<EditLanguage />}
                ></Route>

                {/* role and roleaccess  */}
                <Route path="roles" element={<AllRoles />}></Route>
                <Route path="add-roles" element={<AddRoles />}></Route>
                <Route path="edit-roles/:id" element={<EditRoles />}></Route>
                <Route path="add-quote" element={<AddQuote />}></Route>
                <Route path="edit-quote" element={<EditQuote />}></Route>
                <Route path="invoices" element={<Invoices />}></Route>
                <Route path="add-invoice" element={<AdminInvoice />}></Route>
                <Route path="edit-invoice" element={<EditInvoice />}></Route>
                <Route path="Tickets" element={<Tickets />}></Route>
                <Route path="add-ticket" element={<AddTicket />}></Route>
                <Route path="edit-ticket" element={<EditTicket />}></Route>
                <Route path="Contacts" element={<Contacts />}></Route>
                <Route path="add-contact" element={<AddContact />}></Route>
                <Route path="edit-contact" element={<EditContact />}></Route>
                <Route path="users" element={<Users />}></Route>
                <Route path="add-user" element={<AddUser />}></Route>
                <Route path="edit-user" element={<EditUser />}></Route>
                <Route path="add-invoice" element={<AddUser />}></Route>
                <Route path="Events" element={<Events />}></Route>
                <Route path="add-event" element={<AddEvent />}></Route>

                <Route path="settings" element={<Settings />}></Route>
                <Route
                  path="settings/general-settings"
                  element={<GeneralSettings />}
                ></Route>
                <Route
                  path="settings/general_services"
                  element={<GeneralServices />}
                ></Route>
                <Route
                  path="settings/general_services/add"
                  element={<AddServices />}
                ></Route>
                <Route
                  path="settings/general_services/:id"
                  element={<EditServices />}
                ></Route>
                <Route path="*" element={<h1>Not Found Page</h1>}></Route>
              </Route>
              {/* admin routes end */}
            </Routes>
          </ScrollToTop>
        </div>
      </HelmetProvider>
    </>
  );
}

export default App;
