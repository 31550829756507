import React, { useState, useEffect, useMemo, useCallback } from "react";
import { ProductsTable } from "./ProductsTable";
import { useFetchCurrentPlan, useListProducts } from "../../../api/hooks/products";
import { Modal, Space } from "antd";
import { ProductSearch } from "./ProductSearch";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useListSizes } from "../../../api/hooks/sizes";
import { useListCategories } from "../../../api/hooks/categories";
import { useListColors } from "../../../api/hooks/colors";
import { useListTags } from "../../../api/hooks/tags";
import PopupAlert from "./PopupAlert";

export const ProductManagement = () => {

    const { i18n, t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState(null);
    const [open, setOpen] = useState(false);

    const productsQuery = useListProducts({});
    const currentPlan = useFetchCurrentPlan();
    const tags = useListTags({});
    const colors = useListColors({});
    const categories = useListCategories({});
    const sizes = useListSizes({});

    // Memoize the products to prevent unnecessary re-renders
    const products = useMemo(() => productsQuery?.data || [], [productsQuery?.data]);

  

    // Memoized filtered products
    const filteredProducts = useMemo(() => {
        if (!searchTerm) return products;
        const search = searchTerm.toLowerCase();
        return products?.filter((product) => {
            const name = i18n.language === "ar" ? product.product_name_ar : product.product_name;
            const description = i18n.language === "ar" ? product.product_description_ar : product.product_description;
            return name?.toLowerCase().includes(search) || description?.toLowerCase().includes(search);
        });
    }, [products, searchTerm, i18n.language]);

    return (
        <>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Space className="flex justify-between">
                    <ProductSearch onSearch={useCallback(setSearchTerm, [])} />
                </Space>
                <ProductsTable tags={tags} colors={colors} sizes={sizes} categories={categories} currentPlan={currentPlan} products={filteredProducts} loading={productsQuery.isLoading} />
            </Space>
            {/* popup alert */}
            <PopupAlert  tags={tags} colors={colors} sizes={sizes} categories={categories} />
           
        </>
    );
};

