import { Space, Table } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next';
import DeleteCategoriesIcon from './DeleteCategoriesIcon';
import EditCategoriesIcon from './EditCategoriesIcon';

function CategoriesTable({ categoriesData, loading,onEdit }) {

    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    const columns = [

        {
            title: t("vd_pc_C_ID"),
            key: 'S no',
            dataIndex: "category_id",
            align: isArabic ? 'right' : 'left',
            ellipsis: true, // ✅ Adds "..." if text overflows
        },
        {
            title: t("vd_pc_Category"),
            key: 'name',
            dataIndex: isArabic ? 'category_name_ar' : 'category_name',
            align: isArabic ? 'right' : 'left',
            ellipsis: true, // ✅ Adds "..." if text overflows
        },
        {
            title: t("Description"),
            key: 'price',
            dataIndex: isArabic ? 'category_description_ar' : 'category_description',
            align: isArabic ? 'right' : 'left',
            ellipsis: true,
        },
        {
            title: t("vd_pc_C_Options"),
            key: 'actions',
            width: 120,
            align: 'center',
            fixed: 'right', // ✅ Keeps actions visible on scroll
            render: (category) => (
                <Space>
                    <DeleteCategoriesIcon categories={category} />
                    <EditCategoriesIcon categories={category} onEdit={() => onEdit(category)} />
                </Space>
            ),
        }
    ]
    return (
        <div>

            <Table
                aria-label={t('Products')}
                rowKey="product_id"
                loading={loading}
                dataSource={categoriesData}
                columns={columns}
                pagination={{ pageSize: 10 }}
                bordered
                scroll={{ x: "max-content" }}
                style={{ boxShadow: '0 4px 10px rgba(0,0,0,0.1)', borderRadius: '8px', overflow: 'hidden' }}

            />
        </div>
    )
}

export default CategoriesTable
