import { Drawer, Form, notification } from "antd";
import { EditTwoTone } from "@ant-design/icons";
import React, { useState } from "react";
import { ProductForm } from "./ProductForm";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { getProductById } from "../../../api/products";

export const EditProductIcon = ({ product }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [editData, setEditData] = useState(null);
  const { i18n } = useTranslation();

  const showModal = async () => {
    try {
      const res = await getProductById(product?.product_id);
      const data = res?.data;

      if (data) {
        const transformedData = {
          ...data.product_details[0],
          category_id: data.product_details[0]?.category_id,
          colors: data.colors.map((color) => color.color_id),
          sizes: data.sizes.map((size) => size.size_id),
          tags: data.tags.map((tag) => tag.tag_id),
          product_id: data?.product_details[0]?.product_id,
          thumbnail_image: data.product_details[0]?.thumbnail_image_url
            ? [
                {
                  uid: "-1",
                  name: "thumbnail.jpg",
                  status: "done",
                  url: data.product_details[0].thumbnail_image_url,
                }
              ]
            : [],
          gallery_images: data.images.map((img, index) => ({
            uid: `-${index}`,
            name: `gallery-${index}.jpg`,
            status: "done",
            url: img.image_url,
          })),
        };

        setEditData(transformedData);
        form.setFieldsValue(transformedData);
      }
    } catch (error) {
      console.error("Error fetching product:", error);
    }

    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    form.resetFields();
  };

  const onOk = () => {
    form.submit();
  };

  const onSuccess = (response) => {
    notification.success({
      message: `Product ${response.data[0].product_name} was updated successfully`,
    });

    queryClient
      .invalidateQueries({ queryKey: ["listProducts"] })
      .catch(() =>
        notification.error({ message: "Failed to reload product list" })
      );

    closeModal();
  };

  return (
    <>
      <EditTwoTone onClick={showModal} />

      <Drawer
        title={`Edit Product: ${product.product_name}`}
        width={800}
        open={modalOpen}
        closable
        onClose={closeModal}
        onOk={onOk}
        placement={i18n.language === "ar" ? "left" : "right"}
        style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
      >
        {editData && (
          <ProductForm
            mode="edit"
            product={editData}
            form={form}
            onSuccess={onSuccess}
          />
        )}
      </Drawer>
    </>
  );
};
