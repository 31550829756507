import { useMutation} from '@tanstack/react-query';
import { createOrEditProduct } from "../../products";

export const useCreateOrEditProduct = ({ onSuccess, onError }) => {
    return useMutation({
        mutationKey: [ 'createOrEditProduct' ],
        mutationFn: createOrEditProduct,
        onSuccess,
        onError
   })
}
