import { useMutation } from '@tanstack/react-query';
import { deleteProduct } from "../../products";

export const useDeleteProduct = ({ onSuccess, onError }) => {
    return useMutation({
        mutationKey: ['deleteProduct'], // No need to include `productId`
        mutationFn: (productId) => deleteProduct(productId), // ✅ Dynamically receives `productId`
        onSuccess,
        onError
    });
};