import { Form, Input, } from 'antd'
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';

function CategoriesForm({ onFinish, editingCategory, setEditingCategory,form }) {
    const { t } = useTranslation();

    useEffect(() => {
        if (editingCategory) {
            form.setFieldsValue(editingCategory);
        } else {
            form.resetFields();
        }
    }, [editingCategory]);

    return (
        <div>
            <Form
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                form={form}
                onFinish={onFinish}
                requiredMark="optional"  // Prevents default left-side asterisk
            >
                <Form.Item
                    rules={[{ required: true, message: " Category name must only contain alphabet letters", pattern: /^(?=.{1,30}$)[a-zA-Z ,.'-]+$/i, }]}
                    label={
                        <span className="flex items-center gap-1">
                            {t("vd_pc_Category")}
                            <span className="text-red-500">*</span>
                        </span>
                    }
                    name="category_name"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    rules={[{ required: true, message: " Category name must only contain alphabet letters", pattern: /^[\u0600-\u06FF ,.'-]+$/i, }]}
                    label={
                        <span className="flex items-center gap-1">
                            {t("vd_pc_Category")} {t("in Arabic")}{" "}
                            <span className="text-red-500">*</span>
                        </span>
                    }
                    name="category_name_ar"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    rules={[{ required: true, message: " Category name must only contain alphabet letters", pattern: /^[a-zA-Z ,.'-]+$/i, }]}
                    label={
                        <span className="flex items-center gap-1">
                            {t("Description")}
                            <span className="text-red-500">*</span>
                        </span>
                    }
                    name="category_description"
                >
                    <TextArea />
                </Form.Item>
                <Form.Item
                    rules={[{ required: true, message: " Description must only contain arabic letters", pattern: /^[\u0600-\u06FF ,.'-]+$/i, }]}
                    label={
                        <span className="flex items-center gap-1">
                            {t("Description")} {t("in Arabic")}{" "}
                            <span className="text-red-500">*</span>
                        </span>
                    }
                    name="category_description_ar"
                >
                    <TextArea />
                </Form.Item>
                <button className=" btn btn-primary w-full rounded-md text-white" type="submit">{editingCategory ? t("Update") : t("vd_pc_btn")}</button>
            </Form>
        </div>
    )
}

export default CategoriesForm
