import { Descriptions, Drawer, Image, Space, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetProductById } from "../../../api/hooks/products";

export const ProductDetails = ({ productId }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState(null);
  const getProductQuery = useGetProductById(productId);

  useEffect(() => {
    setOpen(true);
    if (getProductQuery.data?.data) {
      setProduct(getProductQuery.data.data);
    }
  }, [getProductQuery.data]);

  const closeDrawer = () => {
    setOpen(false);
  };

  return (
    <Drawer
      title={t("Product Details")}
      placement="right"
      width={800}
      onClose={closeDrawer}
      open={open}
      loading={getProductQuery.isLoading}
    >
      <Descriptions bordered column={1}>
        <Descriptions.Item label={t("ID")}>
          {product?.product_details?.[0]?.product_id}
        </Descriptions.Item>
        <Descriptions.Item label={t("Name")}>
          {product?.product_details?.[0]?.product_name}
        </Descriptions.Item>
        <Descriptions.Item label={t("SKU")}>
          {product?.product_details?.[0]?.product_sku}
        </Descriptions.Item>
        <Descriptions.Item label={t("Price")}>
          {product?.product_details?.[0]?.product_price}
        </Descriptions.Item>
        <Descriptions.Item label={t("Stock")}>
          {product?.product_details?.[0]?.product_stock}
        </Descriptions.Item>
        <Descriptions.Item label={t("Category")}>
          {product?.product_details?.[0]?.category_name}
        </Descriptions.Item>
        <Descriptions.Item label={t("Status")}>
          {product?.product_details?.[0]?.status === 1
            ? t("Published")
            : t("Draft")}
        </Descriptions.Item>
        <Descriptions.Item label={t("Description")}>
          {product?.product_details?.[0]?.product_description?.replace(
            /<[^>]+>/g,
            ""
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t("Thumbnail")}>
          <Image
            width={100}
            height={70}
            src={product?.product_details?.[0]?.thumbnail_image_url}
            alt="Thumbnail"
          />
        </Descriptions.Item>
        <Descriptions.Item label={t("Tags")}>
          {product?.tags?.map((tag, index) => (
            <Tag>{tag?.tag_name}</Tag>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label={t("Colors")}>
          {product?.colors?.map((color, index) => (
            <Tag>{color.color_name}</Tag>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label={t("Sizes")}>
          {product?.sizes?.map((size, index) => (
            <Tag>{size.size_name}</Tag>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label={t("Gallery Images")}>
          <Space size={8} wrap>
            {product?.images?.map((image, index) => (
              <Image
                width={100}
                height={70}
                key={index}
                src={image?.image_url}
                alt={`Product ${index}`}
              />
            ))}
          </Space>
        </Descriptions.Item>
      </Descriptions>
    </Drawer>
  );
};
