import { Space, Table } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next';
import DeleteSizesIcon from './DeleteSizesIcon';
import EditSizeIcon from './EditSizesIcon';

function SizesTable({ sizesData, loading, onEdit }) {

    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const columns = [
        {
            title: t("vd_pc_C_ID"),
            key: 'S no',
            dataIndex: "size_id",
            align: isArabic ? 'right' : 'left',
            ellipsis: true, // ✅ Adds "..." if text overflows
        },
        {
            title: t("vd_ps_Size"),
            key: 'name',
            dataIndex: isArabic ? 'size_name_ar' : 'size_name',
            align: isArabic ? 'right' : 'left',
            ellipsis: true, // ✅ Adds "..." if text overflows
        },

        {
            title: t("vd_pc_C_Options"),
            key: 'actions',
            width: 120,
            align: 'center',
            fixed: 'right', // ✅ Keeps actions visible on scroll
            render: (sizes) => (
                <Space>
                    <DeleteSizesIcon sizes={sizes} />
                    <EditSizeIcon sizes={sizes} onEdit={() => onEdit(sizes)} />
                </Space>
            ),
        }
    ]
    return (
        <div>
            <Table
                aria-label={t('Sizes')}
                rowKey="sizes_id"
                loading={loading}
                dataSource={sizesData}
                columns={columns}
                pagination={{ pageSize: 10 }}
                bordered
                scroll={{ x: "max-content" }}
                style={{ boxShadow: '0 4px 10px rgba(0,0,0,0.1)', borderRadius: '8px', overflow: 'hidden' }}
            />
        </div>
    )
}

export default SizesTable
