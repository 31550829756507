import { useMutation} from '@tanstack/react-query';
import { createOrEditColor } from "../../colors";

export const useCreateOrEditColor = ({ onSuccess, onError }) => {
    return useMutation({
        mutationKey: [ 'createOrEditColor' ],
        mutationFn: createOrEditColor,
        onSuccess,
        onError
   })
}
