import axios from "axios";
import { headers, baseUrl } from "./utils";

export async function listColors(params) {

  return axios.get(`${baseUrl}/vendor/getallcolor`, { headers }, params).then((r) => r.data.data);
}

export async function deleteColor(colorId) {
  const user = JSON.parse(localStorage.getItem("user"));
  return axios
    .delete(`${baseUrl}/vendor/deletecolor/${colorId}`, {
      headers: {
        Authorization: `Bearer ${user?.token}`, // Set Authorization header
      },
    })
    .then((r) => r.status === 200);
}

export async function createColor(payload) {
  const user = JSON.parse(localStorage.getItem("user"));
  return axios.post(`${baseUrl}/vendor/createcolor`, payload, {
    headers: {
      Authorization: `Bearer ${user?.token}`, // Set Authorization header
    },
  }).then((r) => r.data);
}

export async function editColor(colorId, payload) {
  const user = JSON.parse(localStorage.getItem("user"));
  return axios
    .patch(`${baseUrl}/vendor/updatecolor`, payload, {
      headers: {
        Authorization: `Bearer ${user?.token}`, // Set Authorization header
      },
    })
    .then((r) => r.data);
}

export async function createOrEditColor({ mode, colorId, payload }) {
  switch (mode) {
    case "create":
      return createColor(payload);
    case "edit":
      return editColor(colorId, payload);
    default:
      return null;
  }
}

export async function getColorById(colorId) {
  return axios.get(`${baseUrl}/vendor/colors/${colorId}`).then((r) => r.data);
}
