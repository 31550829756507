import { useMutation} from '@tanstack/react-query';
import { createOrEditTag } from "../../tags";

export const useCreateOrEditTag = ({ onSuccess, onError }) => {
    return useMutation({
        mutationKey: [ 'createOrEditTag' ],
        mutationFn: createOrEditTag,
        onSuccess,
        onError
   })
}
