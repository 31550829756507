import { useQuery } from '@tanstack/react-query';
import {getProductById} from "../../products";
import { notification } from 'antd';

export const useGetProductById = (productId) => {
    const handleError = (err) => {
        notification.error({
            key: 'getProductByIdError',
            message: 'Failed to retrieve product'
        })
    }

    return useQuery({
       queryKey: [ 'getProductById',  productId],
       queryFn: () => getProductById(productId),
       onError: handleError
   })
}
