import { Space, Table } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next';
import DeleteTagsIcon from './DeleteTagsIcon';
import EditTagIcon from './EditTagsIcon';

function TagsTable({ tagsData, loading, onEdit }) {

    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    const columns = [

        {
            title: t("vd_pc_C_ID"),
            key: 'S no',
            dataIndex: "tag_id",
            align: isArabic ? 'right' : 'left',
            ellipsis: true, // ✅ Adds "..." if text overflows
        },
        {
            title: t("vd_pt_tag"),
            key: 'name',
            dataIndex: isArabic ? 'tag_name_ar' : 'tag_name',
            align: isArabic ? 'right' : 'left',
            ellipsis: true, // ✅ Adds "..." if text overflows
        },
        {
            title: t("Description"),
            key: 'name',
            dataIndex: isArabic ? 'tag_description_ar' : 'tag_description',
            align: isArabic ? 'right' : 'left',
            ellipsis: true, // ✅ Adds "..." if text overflows
        },

        {
            title: t("vd_pc_C_Options"),
            key: 'actions',
            width: 120,
            align: 'center',
            fixed: 'right', // ✅ Keeps actions visible on scroll
            render: (tags) => (
                <Space>
                    <DeleteTagsIcon tags={tags} />
                    <EditTagIcon tags={tags} onEdit={() => onEdit(tags)} />
                </Space>
            ),
        }
    ]
    return (
        <div>
            <Table
                aria-label={t('Sizes')}
                rowKey="sizes_id"
                loading={loading}
                dataSource={tagsData}
                columns={columns}
                pagination={{ pageSize: 10 }}
                bordered
                scroll={{ x: "max-content" }}
                style={{ boxShadow: '0 4px 10px rgba(0,0,0,0.1)', borderRadius: '8px', overflow: 'hidden' }}
            />
        </div>
    )
}

export default TagsTable
