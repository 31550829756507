import {useMutation} from '@tanstack/react-query';
import {deleteSize } from "../../sizes";

export const useDeleteSize = ({ sizeId, onSuccess, onError }) => {
    return useMutation({
        mutationKey: [ 'deleteSize', sizeId ],
        mutationFn: (sizeId) => deleteSize(sizeId),
        onSuccess,
        onError
   })
}
