import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useDeleteProduct } from "../../../api/hooks/products";
import { Alert, notification, Popconfirm } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";

export const DeleteProductIcon = ({ product }) => {
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const queryClient = useQueryClient();

    const deleteMutation = useDeleteProduct({
        onSuccess: () => {
            closeConfirmation()
            notification.success({ message: `Product successfully deleted` })
            queryClient.invalidateQueries({ queryKey: ['listProducts'] })
                .catch(() => notification.error({ message: "Failed to reload product list" }))
        },
        onError: (e) => {
            console.error(e)
            setError(true)
        }
    })

    const onOk = () => deleteMutation.mutate(product.product_id)
    const openConfirmation = () => setOpen(true)
    const closeConfirmation = () => {
        setError(false)
        setOpen(false)
    }

    const confirmationContents = React.useMemo(() => {
        return <>
            {`Permanently delete product ${product.product_name}?`}
            {error && <Alert
                type={'error'}
                message='Failed to delete product'
                description='Please try again or contact switch support if the problem persists'
            />}
        </>
    }, [product, error])
    return (
        <Popconfirm
            title={confirmationContents}
            open={open}
            onCancel={closeConfirmation}
            okText="Confirm"
            onConfirm={onOk}
            okButtonProps={{
                loading: deleteMutation.isLoading,
                style: { backgroundColor: "#f4900c", borderColor: "#f4900c", color: "white" }
            }}
            placement='left'
        >
            <DeleteTwoTone twoToneColor='#ff4d4f' onClick={openConfirmation} />
        </Popconfirm>
    )
}
