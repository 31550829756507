import { useMutation } from '@tanstack/react-query';
import { deleteColor } from "../../colors";

export const useDeleteColor = ({ colorId, onSuccess, onError }) => {
    return useMutation({
        mutationKey: ['deleteColor', colorId],
        mutationFn: (colorId) => deleteColor(colorId),
        onSuccess,
        onError
    })
}
