import { useQuery } from "@tanstack/react-query"
import { listSizes } from "../../sizes"
import { notification } from "antd"

export const useListSizes = ({ params }) => {
    const handleError = (err) => {
        notification.error({
            key: "listSizeError",
            message: "Failed to retrieve sizes list"
        })
    }

    return useQuery({
        queryKey: ["listSizes", params],
        queryFn: () => listSizes(params),
        onError: handleError,
    })
}
