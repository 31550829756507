import { Drawer, Form, notification } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { ProductForm } from "./ProductForm";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export const CreateProductButton = ({ tags, colors, sizes, categories,currentPlan }) => {
  const { t, i18n } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const location = useLocation();
  useEffect(() => {
    if (location.state?.tab) {
      setModalOpen(location.state.tab);
    }
}, [location.state]);

  const showModal = () => setModalOpen(true);
  const closeModal = () => {
    setModalOpen(false);
    form.resetFields();
  };

  const onOk = () => {
    form.submit();
  };

  const onSuccess = (response) => {
    if (response) {
      notification.success({
        message: t("product_created_successfully"),
      });
    } else {
      notification.error({
        message: t("something_went_wrong"),
      });
    }
    closeModal();
    queryClient
      .invalidateQueries({ queryKey: ["listProducts"] })
      .catch(() => notification.error({ message: t("failed_reload_products") }));
  };

  return (
    <>
      <button
        onClick={showModal}
        className=" btn btn-primary rounded-md text-white"
        style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
      >
        <PlusCircleOutlined className="mr-2" /> {t("create_product")}
      </button>

      <Drawer
        width={800}
        title={t("create_product")}
        open={modalOpen}
        closable
        placement={i18n.language === "ar" ? "left" : "right"}
        onClose={closeModal}
        onOk={onOk}
        style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
      >
        <ProductForm tags={tags} colors={colors} sizes={sizes} categories={categories} currentPlan={currentPlan} mode="create" form={form} onSuccess={onSuccess} />
      </Drawer>
    </>
  );
};
