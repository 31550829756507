import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import shop from "../../../assets/images/shop.png";
import { BsGlobe } from "react-icons/bs";
import logo from "../../../assets/images/logo.png";
import { useContext } from "react";
import { storeInformation } from "../../../contexts/StoreInformationContext";
import ChangeLanguage from "../../../locales/ChangeLanguage";
import getAll from "../../../hooks/getAll";
import { useState } from "react";
import "./google.css";
import { toast } from "react-toastify";
import { domainProvider } from "../../../contexts/DomainContext";
import axios from "../../utils/Interceptors";
import { useTranslation } from "react-i18next";
import getAllAdmin from "../../../hooks/getAllAdmin";
import { CgProfile } from "react-icons/cg";

const DashboardHeader = ({ role, setSidebarToggle, sidebarToggle }) => {
  const userLogo =
    role === "user"
      ? "https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
      : shop;
  const [siteLogo, setSiteLogo] = useState();
  const user = JSON.parse(localStorage.getItem("user"));
  const admin = JSON.parse(localStorage.getItem("admin"));
  const customer = JSON.parse(localStorage.getItem("customer"));
  const { customdomain, domainstatus, setOrgDomain } =
    useContext(domainProvider);
  const { storeInfo } = useContext(storeInformation);
  const [domainInfo, setDomainInfo] = useState("");
  const { t } = useTranslation();

  const navigate = useNavigate();

  const logOutUser = async () => {
    if (role === "vendor") {
      await axios
        .post(`${process.env.REACT_APP_APIURL}/vendor/logout`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then((res) => {
          toast.success(res.message);
          localStorage.clear();
          navigate("/vendor/login");
        })
        .catch((err) => {
          if (err.response.data.status_code != 200) {
            return toast.error(err.response.data.message);
          }
        });
    } else if (role === "Admin") {
      const res2 = await fetch(`${process.env.REACT_APP_APIURL}/admin/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${admin.token}`,
        },
      });
      const response2 = await res2.json();
      if (response2.status_code == 200) {
        toast.success(response2.message);
      }
      localStorage.clear();
      navigate("/admin/login");
    }
  };

  useEffect(() => {
    const getGeneralSettings = async () => {
      const res = await getAllAdmin("admin/getgeneraldetails");
      setSiteLogo(res?.data[0]?.logo);
    };

    getGeneralSettings();
  }, []);

  const handleSideToggle = () => {
    if (sidebarToggle === true) {
      setSidebarToggle(false);
    } else {
      setSidebarToggle(true);
    }
  };

  // custom domain routing
  useEffect(() => {
    if (role === "vendor") {
      const getAllCustomDomain = async () => {
        const response = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/getallcustomdomainrequest`,
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        const res = await response.data;
        if (res.status_code === 200) {
          if (res.data.is_active === 1) {
            setDomainInfo(res.data);
            setOrgDomain("aitech.com.sa");
          }
        }
      };
      getAllCustomDomain();
    }
  }, [role]);


  return (
    <>
      <div
        className={`${
          role !== "user" && "container"
        } mx-auto navbar p-0 z-50 bg-white border-gray-200 border-b text-white pr-6 md:px-10 pl-5`}
      >
        <div className="navbar-start gap-x-2 w-full flex-col sm:flex-row">
          <div className="flex items-center pt-2 sm:py-2 gap-x-2 w-full sm:w-fit">
            <div
              className={`${
                role === "user" && "hidden"
              } brand-logo lg:w-[240px] p-1 md:p-2`}
            >
              <Link to="/">
                <img
                  src={logo}
                  className="cursor-pointer w-20 md:w-28 text-center mx-auto"
                  alt="logo"
                />
              </Link>
            </div>

            <label
              onClick={handleSideToggle}
              htmlFor="my-drawer-2"
              tabIndex="0"
              className="bg-[#236260] py-[.65rem] px-3 text-white rounded-none drawer-button ml-2 cursor-pointer hover:bg-primary transition-all duration-200 ease-in"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="black"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </label>
          </div>
          {role === "vendor" && (
            <div className="flex items-center justify-start gap-x-2 py-2 w-full sm:w-fit">
              {domainInfo?.is_active === 1 ? (
                <a
                  href={`https://${domainInfo?.full_domain_name}`}
                  className="border items-center roboto justify-center border-gray-300 rounded-none text-gray-500 bg-white capitalize font-Roboto font-normal text-xs md:text-sm gap-x-2 py-2 px-3 h-[40px] hover:bg-[#236260] hover:!text-white duration-500 flex"
                >
                  {" "}
                  <BsGlobe className="w-4" />
                  {t("Browse Website")}
                </a>
              ) : (
                <Link
                  to={`/${storeInfo?.store_name}`}
                  className="border items-center roboto justify-center border-gray-300 rounded-none text-gray-500 bg-white capitalize font-Roboto font-normal text-xs md:text-sm gap-x-2 py-2 px-3 h-[40px] hover:bg-[#236260] hover:!text-white duration-500 flex"
                >
                  <BsGlobe className="w-4" />
                  {t("Browse Website")}
                </Link>
              )}
              <Link
              state={{ tab: true }}  
                to="products"
                className="border items-center roboto justify-center border-gray-300 rounded-none text-gray-500 bg-white capitalize font-Roboto font-normal text-xs md:text-sm gap-x-2 py-1 px-3 h-[40px] hover:bg-[#236260] hover:!text-white duration-500 flex"
              >
                + {t("all_product_add")}
              </Link>
            </div>
          )}
        </div>
        <div className="navbar-end gap-x-1 md:gap-x-5 sm:w-2/5">
          {role !== "user" && <ChangeLanguage />}

          <div
            className={`${
              role === "user" && "hidden"
            } dropdown dropdown-end flex items-center gap-x-1`}
          >
            {role === "vendor" && (
              <p className="text-sm text-black hidden md:block">
                {user?.vendor_name}
                <br />
                <span className="text-xs flex-end">Seller</span>
              </p>
            )}
            {role === "Admin" && (
              <p className="text-sm text-black hidden md:block">
              Admin
              </p>
            )}
            {role === "user" && (
              <p className="text-sm text-black hidden md:block">
                {customer?.data?.cust_fullname || user?.admin_name}
                <br />
                <span className="text-xs flex-end">{role}</span>
              </p>
            )}

            <div>
              <label tabIndex="0" className="btn btn-ghost btn-circle avatar">
                <div className="">
                  {user?.profile_image ? (
                    <img src={user?.profile_image} alt="userlogo" />
                  ) : admin?.profile_image ? (
                    <img src={admin?.profile_image} alt="userlogo" />
                  ) : (
                    <CgProfile color="#000" size={28} />
                  )}
                </div>
              </label>
              <ul
                tabIndex="0"
                className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-40 md:w-52 text-black"
              >
                <li>
                  {role === "vendor" ? (
                    <Link to="/vendor/profile" className="justify-between">
                      Profile
                    </Link>
                  ) : (
                    <Link to="/admin/profile" className="justify-between">
                      Profile
                    </Link>
                  )}
                </li>
                <li onClick={logOutUser}>
                  <a>Logout</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardHeader;
