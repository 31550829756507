import { useMutation} from '@tanstack/react-query';
import { createOrEditCategory } from "../../categories";

export const useCreateOrEditCategory = ({ onSuccess, onError }) => {
    return useMutation({
        mutationKey: [ 'createOrEditCategory' ],
        mutationFn: createOrEditCategory,
        onSuccess,
        onError
   })
}
